import React from "react";

export const CredentialingUserDetail = ({userDashboardData}) => {
    return (
        <div className="card-body mb-3">
            <div>
                Providers: <span className="float-end">{userDashboardData?.providers}</span>
            </div>
            <hr/>
            <div>
                Practices: <span className="float-end">{userDashboardData?.practuces}</span>
            </div>
            <hr/>
            <div>
                Insurances: <span className="float-end">{userDashboardData?.total_insurances}</span>
            </div>
        </div>
    )
}
