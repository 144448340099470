import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "./context/AppContext";
import {bgImgFunc, formatDateDMY} from "../general/utils";
import {Link, useNavigate} from "react-router-dom";
import {CustomPagination} from "../general/CustomPagination";
import {HeaderTitle} from "../general/HeaderTitle";
import {KTSVG} from "../general/KTSVG";
import {SearchFilter} from "./components/SearchFilter";
import {Loader} from "../general/Loader";
import ReadMoreReact from "read-more-react";
const AppList = () => {
    const {appData, getAppList, setLoading, isLoading} = useContext(AppContext)
    const navigate = useNavigate();
    const initialFormData = {
        search_string:'',
        practice_provider:'',
        insurance:'',
        start_date:'',
        end_date:'',
        status:'',
    };
    const [form, setForm] = useState(initialFormData);

    const [currentPage, setCurrentPage] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const recordsPerPage = 10;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const [searchList, setSearchList] = useState(appData);
    const [providerNames, setProviderNames] = useState([]);

    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const filterByName = (event) => {
        const query = event.target.value.toLowerCase();
        const filteredList = appData.filter((item) => {
            const searchFields = [
                item.insurance.title.toLowerCase(),
                item.credential.provider_name?.toLowerCase(),
                item.credential.group_name?.toLowerCase(),
            ];
            return searchFields.some((field) => field && field.includes(query));
        });

        setSearchList(filteredList);
    };
    const applySearchFilter = (searchFilter) => {

        const filteredList = appData.filter((item) => {
            const insuranceTitle = item.insurance.title.toLowerCase();
            const providerName = item.credential.provider_name?.toLowerCase();
            const groupName = item.credential.group_name?.toLowerCase();
            const lastUpdateDate = item.last_update_date?.toLowerCase();
            const status = item.status?.toLowerCase();

            const isInsuranceMatch = !searchFilter.selectInsurance || insuranceTitle.includes(searchFilter.selectInsurance.value.toLowerCase());
            const isProviderMatch = !searchFilter.selectedProvider || (providerName && providerName.includes(searchFilter.selectedProvider.value.toLowerCase())) || (groupName && groupName.includes(searchFilter.selectedProvider.value.toLowerCase()));
            const isStartDateMatch = !searchFilter.startDate || (lastUpdateDate && new Date(lastUpdateDate) >= new Date(searchFilter.startDate));
            const isStatusMatch = !searchFilter.status || (status && status.includes(searchFilter.status.toLowerCase()));

            return isInsuranceMatch && isProviderMatch && isStartDateMatch && isStatusMatch;
        });

        setSearchList(filteredList);
    };


    useEffect(() => {
        setLoading(true);
        getAppList().then((response) => {
            setSearchList(response);
        });
        setLoading(false);
        // const providerName = appData.map(item => item?.credential?.provider_name);
        // setProviderNames(providerName);
    }, [])

    const clearFilter = () => {
        setForm(initialFormData);
        setSearchList(appData);
        setShowFilter(!showFilter);
    };
    const currentRecords = searchList.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
    //console.log('currentRecords', currentRecords)
    return (
        <>
            {isLoading ? (<Loader />) : (
                <div className="container-fluid">
                    <div className="row mx-0 align-items-center align-middle">
                        <div className="col-12 col-md-10">
                            <HeaderTitle title="Application" />
                        </div>
                        <div className="col-12 col-md-2 mt-3 mt-md-0">
                            <input
                                id='search_string'
                                className={`form-control `}
                                defaultValue={form.search_string}
                                placeholder='Search'
                                onChange={filterByName}
                            />
                        </div>
                        {/*<div className="col-1">*/}
                        {/*    <button className="btn btn-transparent resetBtn bg-transparent me-2 text-start border-0" onClick={clearFilter} ><KTSVG path='/svg/filter.svg' className='filterSearch pe-1' /></button>*/}
                        {/*    {showFilter && <SearchFilter show={showFilter} onClose={ () => setShowFilter(false)} providerList={searchList} applySearchFilter={applySearchFilter} />}*/}
                        {/*</div>*/}
                    </div>
                    <div className="row my-4">
                        <div className="col-12">
                            <div className="">
                                <table className="table bg-transparent customTbl border-1">
                                    <thead className="card-header-style text-white fw-semibold fs-8 text-white headerBgImg text-uppercase align-middle align-items-center">
                                    <tr>
                                        <th className="text-white ps-4">Application Name</th>
                                        <th className="text-white">Status</th>
                                        <th className="text-white">Last Update</th>
                                        <th className="text-white">Last Message</th>
                                    </tr>
                                    </thead>
                                    <tbody className="fs-8">
                                    {currentRecords.map((item, index) => (
                                        <tr onClick={ () =>  navigate('/application/'+item.id, { state: {item} })} key={index} className="align-middle align-items-center data-table-row bg-gray cursorPointer cursor-pointer">
                                            <td className="ps-4">
                                                {
                                                    item.credential?.form_type === 'credentialing_individual_provider' ? item.credential?.provider_name : item.credential?.group_name
                                                }
                                                ({item.insurance.title})</td>
                                            <td className={`clr${item.insurance_status}`}>
                                                {item.insurance_status}
                                                <div className={item?.is_application_paid == 1 ? 'text-success' : 'text-danger'}>{item?.is_application_paid == 1 ? '(Paid)' : ''}</div>
                                            </td>
                                            <td>{formatDateDMY(item.last_update_date)}</td>
                                            <td>
                                                <Link to= {{ pathname: '/application/'+item.id }}
                                                      state={{item}} key={index} className="bg-gray-list text-start text-decoration-none text-black">
                                            <span className="">
                                                {item?.insurance_timeline?.length>0?(
                                                    <ReadMoreReact
                                                        text={item?.insurance_timeline?.[0]?.message}
                                                        min={30}
                                                        ideal={40}
                                                        readMoreText="read more..."
                                                    />
                                                ):''}
                                            </span>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <CustomPagination
                                    totalRecords={searchList.length}
                                    recordsPerPage={recordsPerPage}
                                    onPageChange={onPageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AppList;
