import React, {useContext, useState} from "react";
import {Row, Col, Form} from "react-bootstrap";
import {BillingReportContext} from "../context/BillingReportContext";

export const BillingReportForm = () => {

    const {getBillingReport} = useContext(BillingReportContext);
    const [form, setForm] = useState({
        claim_no: '',
        claim_status: 'all',
        insurance: 'all',
        date_by: 'all',
        start_date: '',
        end_date: '',

    });
    const handleSubmit = (e) => {
        e.preventDefault();
        getBillingReport(form);
    };

    return (

        <div className={'p-5'}>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <h6 className="header-text text-uppercase">Search</h6>
                    <Col md={12}>
                        <Row>
                            <Col md={2}>
                                <div className="form-group mb-3">
                                    <label htmlFor='name'>Date by</label>
                                    <select className={`form-control`} value={form?.date_by}
                                            onChange={(e) => setForm({...form, date_by: e.target.value})}>
                                        <option value="all">All</option>
                                        <option value="submitted">Submitted</option>
                                        <option value="batch">Batch</option>
                                    </select>

                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form-group mb-3">
                                    <label htmlFor='name'>Batch Start</label>
                                    <input className={`form-control`} type={'date'} value={form.start_date}
                                           onChange={(e) => setForm({...form, start_date: e.target.value})}/>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form-group mb-3">
                                    <label htmlFor='name'>Batch End</label>
                                    <input className={`form-control`} type={'date'} value={form.end_date}
                                           onChange={(e) => setForm({...form, end_date: e.target.value})}/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <div className="form-group mb-3">
                            <label htmlFor='name'>Claim No</label>
                            <input type={'number'} id='claim_no' className={`form-control`} value={form.claim_no}
                                   onChange={(e) => setForm({...form, claim_no: e.target.value})}/>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="form-group mb-3">
                            <label htmlFor='name'>Status</label>
                            <select className={`form-control`} value={form.claim_status}
                                    onChange={(e) => setForm({...form, claim_status: e.target.value})}>
                                <option value="all">All</option>
                                <option value="paid">Paid</option>
                                <option value="re_submission">Resubmitted</option>
                                <option value="rejected">Rejected</option>
                            </select>

                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="form-group mb-3">
                            <label htmlFor='name'>Insurance</label>
                            <select className={`form-control`} value={form.insurance}
                                    onChange={(e) => setForm({...form, insurance: e.target.value})}>
                                <option value="all">All</option>
                                <option value="AETNA">AETNA</option>
                                <option value="CIGNA">CIGNA</option>
                                <option value="OSCAR">OSCAR</option>
                                <option value="BCBS TEXAS">BCBS TEXAS</option>
                                <option value="UNITED HEALTHCARE">UNITED HEALTHCARE</option>
                                <option value="INMAR HEALTH PLAN">INMAR HEALTH PLAN</option>
                                <option value="SS HEALTH (AETNA BUZZBALL)">SS HEALTH (AETNA BUZZBALL)</option>
                                <option value="AMBETTER BALANCED CARE">AMBETTER BALANCED CARE</option>
                                <option value="(AETNA) GRAVIE ADMINISTRATTIVE SERVICES">(AETNA) GRAVIE ADMINISTRATTIVE SERVICES</option>
                            </select>
                        </div>
                    </Col>
                    <Col md={12}>
                        <button type="submit" className="btn btn-primary btn-sm float-end m-2 p-2">Search Now</button>
                    </Col>
                </Row>
            </Form>
        </div>

    );
};
