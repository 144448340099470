import React, {useContext, useEffect, useMemo, useState} from 'react';
import {formatCurrency, formatDateDMY} from "../../general/utils";
import {CustomPagination} from "../../general/CustomPagination";
import {NotFound} from "../../general/NotFound";
import {CSVLink} from "react-csv";
import DateRangePicker from "../../general/DateRangePicker";
import {DashboardContext} from "../context/DashboardContext";
import {Loader} from "../../general/Loader";

export const BillingDashboardPaymentReport = ({ topFiveInsurances=[], setTopPaidInsurance=[], resetPaymentReport}) => {
    const{billingDashboardData, reportingDate, selectedMonth, selectedYear, individualLoader, reportingTitle, paymentDate} = useContext(DashboardContext);

    const [dateState, setDateState] = useState({});
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('All');
    const [filteredBillingData, setFilteredBillingData] = useState(reportingDate);

    useEffect(() => {
        setFilteredBillingData(reportingDate);
    }, [reportingDate, paymentDate]);

    const recordsPerPage = 20;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentBillingData = filteredBillingData.slice(indexOfFirstRecord, indexOfLastRecord);
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
console.log('paymentDate', paymentDate)
console.log('reportingDate', reportingDate)
    const headers = [
        { label: "Batch ID", key: "batch_id" },
        { label: "Payment ID", key: "payment_id" },
        { label: "Provider", key: "provider" },
        { label: "Payment From", key: "payment_from" },
        { label: "Payment Type", key: "payment_type" },
        { label: "Cheque No", key: "cheque_no" },
        { label: "Cheque Date", key: "cheque_date" },
        { label: "Payment Date", key: "payment_date" },
        { label: "Amount", key: "amount" }
    ];

    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        setFilter(selectedFilter);
        setCurrentPage(1);
        setFilteredBillingData(
            selectedFilter === 'All'
                ? reportingDate
                : reportingDate.filter(item => item?.payment_from === selectedFilter)
        );
        setDateState({});
    };

    const handleResetDate = () => {
        setFilteredBillingData(reportingDate);
        setFilter('All');
        setCurrentPage(1);
        setDateState({});
        setError(null);
        resetPaymentReport();
    };

    return (
        <div className="container-fluid">
            {individualLoader?.payment ? <Loader /> : (
            <div className="row my-4">
                <div className="col-12 d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap">
                    <h5 className="w-100 w-md-auto mb-2 mb-md-0">
                        {paymentDate?.from
                            ? `From ${paymentDate.from} ${paymentDate?.to && paymentDate?.to !== paymentDate?.from ? ` To ${paymentDate.to} ` : ''}`
                            : ` ${selectedMonth || ''} ${selectedYear || ''} `}
                            Payment Report {filteredBillingData?.length > 0 ? `(${filteredBillingData.length})` : ''}
                    </h5>
                    <div className="w-100 w-md-auto mb-2 mb-md-0">
                        <CSVLink
                            data={filteredBillingData}
                            headers={headers}
                            filename={`reporting_list_${new Date().toISOString()}.csv`}
                            className="btn btn-sm mb-2 btnReportDownload text-primary"
                        >
                            <i className="fa fa-download me-2 text-primary"></i>
                            Download
                        </CSVLink>
                    </div>
                    <select className="form-select w-100 w-auto" onChange={handleFilterChange} value={filter}>
                        <option value="All">All</option>
                        {
                            topFiveInsurances?.map((item, index) =>
                                <option key={index} value={item?.payment_from}>{item?.payment_from}</option>)
                        }
                    </select>
                </div>
                <div className="col-12 mt-3">
                    <div className="table-responsive-sm">
                        {currentBillingData.length === 0 ? (
                            <div className="alert alert-warning" role="alert">
                                <NotFound message="Data not found for the selected filter." border={false}
                                          fontSize="fs-5"/>
                            </div>
                        ) : (
                            <>
                                <table className="table bg-transparent customTbl border-1">
                                    <thead
                                        className="card-header-style text-white fw-semibold fs-8 text-white headerBgImg text-uppercase align-middle align-items-center">
                                    <tr>
                                        <th className="text-white ps-4">Batch ID</th>
                                        <th className="text-white">Payment ID</th>
                                        <th className="text-white">Provider</th>
                                        <th className="text-white">Payment From</th>
                                        <th className="text-white">Payment Type</th>
                                        <th className="text-white">Cheque No</th>
                                        <th className="text-white">Cheque Date</th>
                                        <th className="text-white">Payment Date</th>
                                        <th className="text-white">Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody className="fs-8">
                                    {currentBillingData.filter(item => item?.amount != 0).map((payment, index) => (
                                        <tr key={index}
                                            className="align-middle align-items-center data-table-row bg-gray cursorPointer cursor-pointer">
                                            <td>{payment.batch_id}</td>
                                            <td>{payment.payment_id}</td>
                                            <td>{payment.posted_by}</td>
                                            <td>{payment.payment_from}</td>
                                            <td>{payment.payment_type}</td>
                                            <td>{payment.cheque_no}</td>
                                            <td>{formatDateDMY(payment.cheque_date)}</td>
                                            <td>{formatDateDMY(payment.payment_date)}</td>
                                            <td>$ {formatCurrency(payment.amount)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <CustomPagination totalRecords={filteredBillingData?.length}
                                                  recordsPerPage={recordsPerPage} onPageChange={onPageChange}/>
                            </>
                        )}
                    </div>
                </div>
            </div>
                )
            }
        </div>
    );
};