import React, {useContext, useMemo, useState} from 'react';
import {formatDateDMY} from "../../general/utils";
import {CustomPagination} from "../../general/CustomPagination";
import {NotFound} from "../../general/NotFound";
import {CSVLink} from "react-csv";
import {DashboardContext} from "../context/DashboardContext";
import {Loader} from "../../general/Loader";

export const BillingDashboardReport = () => {
    const{reportingDate, selectedMonth, selectedYear, individualLoader, reportingTitle} = useContext(DashboardContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('All');
    const [reSubmissionCount, setReSubmissionCount] = useState(null);
    const recordsPerPage = 20;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;


    // Filter billing data based on the selected filter
    const filteredBillingData = filter === 'All'
        ? reportingDate
        : reportingDate.filter(item => {
            if (filter === 'process') {
                return item?.from_tbl === 'submission_claim';
            } else if (filter === 're_submission') {
                return item?.claim_status === 're_submission' && item?.from_tbl === undefined;
            } else if (filter === 'rejected') {
                return item?.claim_status === 'rejected' && item?.from_tbl === undefined;
            } else{
                return reportingDate
            }
        });
    // Grouping records by `claim_no` and counting the resubmissions
    const groupedBillingData = useMemo(() => {
        const grouped = filteredBillingData.reduce((acc, item) => {
            if (!acc[item.claim_no]) {
                acc[item.claim_no] = {
                    ...item,
                    resubmissionCount: 0
                };
            }
            if (item.claim_status === "re_submission") {
                acc[item.claim_no].resubmissionCount += 1;
            }
            return acc;
        }, {});
        return Object.values(grouped).sort((a, b) => b.resubmissionCount - a.resubmissionCount);
    }, [filteredBillingData]);

    const currentBillingData = filteredBillingData.slice(indexOfFirstRecord, indexOfLastRecord);
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const handleFilterChange = (event) => {
        if(event.target.value === 'claim_resubmitted_count'){
            setReSubmissionCount('claim_resubmitted_count');
            setFilter('re_submission');
        }else{
            setFilter(event.target.value);
            setReSubmissionCount(null);
        }
        setCurrentPage(1);
    };


    const downloadData = useMemo(() => {
        return filteredBillingData.map((item, index) => ({
            sn: index + 1,
            ...item
        }));
    }, [filteredBillingData]);

    const headers = [
        { label: "SN#", key: "sn" },
        { label: "Claim No", key: "claim_no" },
        { label: "Insurance", key: "insurance" },
        { label: "Provider", key: "provider" },
        { label: "Charges", key: "charges" },
        { label: "Service Date", key: "service_date" },
        { label: "Type", key: "claim_status" }
    ];

    return (
        <div className="container-fluid">
            {individualLoader?.monthly || individualLoader?.weekly ? <Loader /> : (
            <div className="row my-4">
                <div className="col-12 d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap">
                    <h5 className="w-100 w-md-auto mb-2 mb-md-0">{selectedMonth && selectedMonth} {selectedYear && selectedYear} Billing {reportingTitle} Report {filteredBillingData?.length > 0 && `(${filteredBillingData?.length})`}</h5>
                    <div className="w-100 w-md-auto mb-2 mb-md-0">
                        <CSVLink
                            data={downloadData}
                            headers={headers}
                            filename={`reporting_list_${new Date().toISOString()}.csv`}
                            className="btn btn-sm mb-2 btnReportDownload text-primary"
                        >
                            <i className="fa fa-download me-2 text-primary"></i>
                            Download
                        </CSVLink>
                    </div>
                    <select className="form-select w-100 w-auto" onChange={handleFilterChange} value={filter}>
                        <option value="All">All</option>
                        <option value="process">Submitted</option>
                        <option value="re_submission">Resubmitted</option>
                        <option value="rejected">Rejected</option>
                        {/*<option value="claim_resubmitted_count">Claim Resubmitted Count</option>*/}
                    </select>
                </div>
                <div className="col-12 mt-3">
                    <div className="table-responsive-sm">
                        {currentBillingData.length === 0 ? (
                            <div className="alert alert-warning" role="alert">
                                <NotFound message="Data not found for the selected filter." border={false}
                                          fontSize="fs-5"/>
                            </div>
                        ) : (
                            <>
                                <table className="table bg-transparent customTbl border-1">
                                    <thead
                                        className="card-header-style text-white fw-semibold fs-8 text-white headerBgImg text-uppercase align-middle align-items-center">
                                    <tr>
                                        <th className="text-white ps-4">SN#</th>
                                        <th className="text-white">Claim No</th>
                                        <th className="text-white">Insurance</th>
                                        <th className="text-white">Provider</th>
                                        <th className="text-white">Charges</th>
                                        <th className="text-white">Service Date</th>
                                        <th className="text-white">Type</th>
                                        <th className="text-white">Status</th>
                                        {reSubmissionCount === "claim_resubmitted_count" && (
                                            <th className="text-white">Count</th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody className="fs-8">
                                    {currentBillingData.map((billing, index) => (
                                        <tr key={index} className="align-middle align-items-center data-table-row bg-gray cursorPointer cursor-pointer">
                                            <td className="ps-4"> {(currentPage - 1) * recordsPerPage + index + 1}</td>
                                            <td>{billing?.claim_no}</td>
                                            <td>{billing?.insurance}</td>
                                            <td>{billing?.provider}</td>
                                            <td>${billing?.charges}</td>
                                            <td>{formatDateDMY(billing?.service_date)}</td>
                                            <td>{billing?.claim_status}</td>
                                            <td>
                                                <span className={`badge ${billing?.status === '0' ? 'bg-success' : 'bg-warning-light text-brown-dark'}`}>
                                                     {billing?.status === '0' ? 'Pending' : 'Processed'}
                                                </span>
                                            </td>
                                            {reSubmissionCount === "claim_resubmitted_count" && (
                                                <td>{billing?.resubmissionCount}</td>
                                            )}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <CustomPagination totalRecords={filteredBillingData?.length} recordsPerPage={recordsPerPage} onPageChange={onPageChange}/>
                            </>
                        )}
                    </div>
                </div>
            </div>
            )}
        </div>
    );
};