import {Modal, Row, Col, Button} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import {customAlert} from "../../general/helpers";
import ReCAPTCHA from "react-google-recaptcha";

export const RegisterCustomerModal = ({show, close}) => {
    const {AddNewCustomer} = useContext(AuthContext);
    const initialFormData ={
        full_name: '',
        business_name: '',
        email: '',
        phone_number: '',
        npi: '',
        customer_type: '',
        new_password: '',
        confirm_password: '',
    }
    const [form, setForm] = useState(initialFormData);
    const [errors, setErrors] = useState({});
    const [showNewPassword, setShowNewPassword] = useState({new_password: false, confirm_password: false});
    const [passwordMatch, setPasswordMatch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    let siteKey = process.env.REACT_APP_SITE_KEY;
    let secretKey = process.env.REACT_APP_SECRET_KEY;

    const toggleShowNewPassword = (inputField) => {
        setShowNewPassword((prevShowPassword) => ({
            ...prevShowPassword,
            [inputField]: !prevShowPassword[inputField]
        }));
    }

    const handleCaptchaChange = (response) => {
        if (response) {
            setIsVerified(true);
        } else {
            console.error("Verification failed!");
        }
    };
    const handleAddCustomer = (e) => {
        e.preventDefault();
        setIsLoading(true);
        const { full_name, email, phone_number, new_password, confirm_password, customer_type } = form;
        const validationErrors = {};

        if (!full_name.trim()) {
            validationErrors.full_name = 'Full Name is Required!';
        }
        if (!email.trim()) {
            validationErrors.email = 'Email is Required!';
        }
        if(!isEmailPatternValid(email)) {
            validationErrors.email = 'Invalid Email Format';
        }
        if (!customer_type.trim()) {
            validationErrors.customer_type = 'Customer Type is Required!';
        }
        if (!phone_number.trim()) {
            validationErrors.phone_number = 'Phone Number is Required!';
        }
        if (!new_password.trim()) {
            validationErrors.new_password = 'New Password is Required!';
        }
        if (!confirm_password.trim() ) {
            validationErrors.confirm_password = 'Confirm Password is Required!';
        }
        if (isVerified === false) {
            validationErrors.captcha = 'Captcha is Required!';
        }
        if (Object.keys(validationErrors).length === 0) {
            const formData = new FormData(e.target.closest('form'));
            AddNewCustomer(formData).then(res => {
                if(res.status.toLowerCase() === 'success'){
                    customAlert(res.result, 'success');
                    setErrors({});
                    setForm(initialFormData);
                    close();
                    setIsLoading(false);
                }else {
                    customAlert(res.result, 'error');
                    setIsLoading(false);
                    close();
                }
            });
            setErrors({});
        } else {
            setErrors(validationErrors);
            setIsLoading(false);
        }
    }
    const isEmailPatternValid = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    useEffect(() => {
        if(form.new_password){

            if (form.new_password === form.confirm_password) {
                setPasswordMatch('yes');
            } else {
                setPasswordMatch('no');
            }
        }
    }, [form.new_password, form.confirm_password]);

    return (
        <Modal show={show} onHide={ () => close()} size="lg" className="customModalClass registerNewCustomer" backdrop="static" keyboard={false}>
            <Modal.Header closeButton className="bg-primary py-3 px-6">
                <Modal.Title className="text-white">Sign Up</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e)=>{handleAddCustomer(e)}}>
                <Row>
                    <Col md={6}>
                        <label className="font-weight-bold primary">Full Name <span className="text-primary">*</span></label>
                        <input
                            type="text"
                            className={`form-control ${errors.full_name ? 'is-invalid' : ''}`}
                            name="full_name"
                            placeholder="Full Name *"
                            value={form.full_name}
                            onChange={(e) =>
                                setForm({ ...form, full_name: e.target.value })
                            }
                            required
                        />
                        {errors.full_name && <span className="invalid-feedback">{errors.full_name}</span>}
                    </Col>
                    <Col md={6}>
                        <label className="font-weight-bold primary">Email <span className="text-primary">*</span></label>
                        <input
                            type="email"
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            name="email"
                            id="email_id"
                            placeholder="Email *"
                            value={form.email}
                            autoComplete="off"
                            onChange={(e) => setForm({ ...form, email: e.target.value })}
                            required
                        />
                        {errors.email && (<span className={`invalid-feedback d-block `}>{errors.email}</span>)}
                    </Col>
                    <Col md={6}>
                        <label className="font-weight-bold primary mt-3">
                            Phone Number <span className="text-primary">*</span>
                        </label>
                        <input
                            type="number"
                            className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
                            name="phone_number"
                            value={form.phone_number}
                            placeholder="Phone Number *"
                            onChange={(e) =>
                                setForm({ ...form, phone_number: e.target.value })
                            }
                            required
                            pattern="[0-9]{10}"
                            title="Phone Number must be 10 Digits"
                        />
                        {errors.phone_number && <span className="invalid-feedback">{errors.phone_number}</span>}
                    </Col>
                    <Col md={6}>
                        <label className="font-weight-bold primary mt-3">Business Name</label>
                        <input
                            type="text"
                            className="form-control"
                            name="business_name"
                            placeholder="Business Name"
                            value={form.business_name}
                            onChange={(e) =>
                                setForm({ ...form, business_name: e.target.value })
                            }
                        />
                    </Col>
                    <Col md={6}>
                        <label className="font-weight-bold primary mt-3">Customer Type *</label>
                        <select
                            className={`form-select customSelect form-control ${errors.customer_type ? 'is-invalid' : ''}`}
                            name="customer_type"
                            value={form.customer_type}
                            onChange={(e) =>
                                setForm({ ...form, customer_type: e.target.value })
                            }
                        >
                            <option value="none">--Select--</option>
                            <option value="credentialing">Credentialing</option>
                            <option value="billing">Billing</option>
                            <option value="both">Both</option>
                        </select>
                        {errors.customer_type && <span className="invalid-feedback">{errors.customer_type}</span>}
                    </Col>
                    <Col md={6}>
                        <label className="font-weight-bold primary mt-3">NPI</label>
                        <input
                            type="text"
                            className="form-control"
                            name="npi"
                            placeholder="NPI"
                            value={form.npi}
                            onChange={(e) =>
                                setForm({ ...form, npi: e.target.value })
                            }
                        />
                    </Col>
                    <Col md={6}>
                        <label className="font-weight-bold primary mt-3">New Password <span className="text-primary">*</span></label>
                        <div className={`align-items-center d-flex form-control justify-content-between ${passwordMatch === 'yes' ? 'is-valid' : passwordMatch === 'no' ? 'is-invalid' : ''}`}>
                            <input
                                id='new_password'
                                type={showNewPassword['new_password'] ? 'text' : 'password'}
                                className={`custom-input-pass border-0 w-100`}
                                value={form.new_password}
                                name="new_password"
                                placeholder="New Password *"
                                onChange={(e) =>
                                    setForm({ ...form, new_password: e.target.value })
                                }
                                autoComplete="off"
                                required
                            />
                            <i className={showNewPassword['new_password'] ? 'fa fa-eye-slash' : 'fa fa-eye'} onClick={() => toggleShowNewPassword('new_password')} >
                            </i>
                        </div>
                        {errors.new_password && <span className="invalid-feedback">{errors.new_password}</span>}
                    </Col>
                    <Col md={6}>
                        <label className="font-weight-bold primary mt-3">Confirm Password <span className="text-primary">*</span></label>
                        <div className={`align-items-center d-flex form-control justify-content-between  ${passwordMatch === 'yes' ? 'is-valid' : passwordMatch === 'no' ? 'is-invalid' : ''}`}>
                            <input
                                id='confirm_password'
                                type={showNewPassword['confirm_password'] ? 'text' : 'password'}
                                className={`custom-input-pass border-0 w-100`}
                                value={form.confirm_password}
                                placeholder="Confirm Password *"
                                name="confirm_password"
                                onChange={(e) =>
                                    setForm({ ...form, confirm_password: e.target.value })
                                }
                                autoComplete="off"
                                required
                            />
                            <i className={showNewPassword['confirm_password'] ? 'fa fa-eye-slash bgClrBlue' : 'fa fa-eye'} onClick={() => toggleShowNewPassword('confirm_password')} >
                            </i>
                        </div>
                        {errors.confirm_password && <span className="invalid-feedback">{errors.confirm_password}</span>}
                        {passwordMatch === 'no' && (<span className="invalid-feedback">Passwords do not match</span>)}
                    </Col>
                </Row>
                    <Row>
                        <Col md={12} className={`d-flex justify-content-center mt-4 ${errors.captcha ? 'is-invalid' : ''}`}>
                            <ReCAPTCHA
                                sitekey="6LfuNqApAAAAABGz_949SdMD8p4GhBlgwc6rUUWx"
                                onChange={handleCaptchaChange}
                                style={{ transform: 'scale(0.8)', transformOrigin: '0 0' }}
                            />
                        </Col>
                            {errors.captcha && <span className="invalid-feedback text-center">{errors.captcha}</span>}
                    </Row>
                    <Row>
                        <Col className="d-flex justify-content-between border-0 mt-4 mb-2">
                            <Button variant="danger btn-sm fw-bold px-4 py-2" onClick={() => close()}>
                                Close
                            </Button>
                            <Button variant="primary btn-sm fw-bold px-4 py-2" disabled={!passwordMatch} onClick={handleAddCustomer}>
                                {isLoading ? 'Loading...' : 'Sign Up'}
                            </Button>
                        </Col>
                    </Row>
                </form>
            </Modal.Body>
        </Modal>
    )
}