import React, {useContext, useEffect, useState} from "react";
import { Row, Col, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import {BillingContext} from "../../context/BillingContext";

let check = 0;
export const Step3CreateLogins = () => {
    const {billingLists, formContext, saveLoginDetails, removeBillingDetail} = useContext(BillingContext);
    const [showPassword, setShowPassword] = useState(false);
    const [form, setForm] = useState({
        billing_group_id: "",
        ehr_access: {},
        practice_management_access: {},
        clearing_house: {},
        other_platforms: [],
    });
    useEffect(() => {
        if(formContext?.billing_group_id) {
            let logins = billingLists.loginList.filter(item=>item.billing_group_id == formContext.billing_group_id);
            if(logins.length > 0){
                setTransformedForm(logins, formContext.billing_group_id);
            }
            return
        }
        if(form.billing_group_id) {
            let logins = billingLists.loginList.filter(item=>item.billing_group_id == form.billing_group_id);
            if(logins.length > 0){
                setTransformedForm(logins,  form.billing_group_id);
            }
        } else {
            setForm({
                billing_group_id: "",
                ehr_access: {},
                practice_management_access: {},
                clearing_house: {},
                other_platforms: [],
            })
        }
    }, [form.billing_group_id]);
    let setTransformedForm = (logins, billing_group_id) => {
        let transformedForm = {
            billing_group_id: "",
            ehr_access: {},
            practice_management_access: {},
            clearing_house: {},
            other_platforms: [],
        }
        transformedForm.billing_group_id = billing_group_id;
        logins.forEach((item) => {
            if (item.software_name === "other_platforms") {
                transformedForm.other_platforms.push(item);
            } else {
                transformedForm[item.software_name] = {
                    password: item.password || "",
                    id: item.id || "",
                    name: item.name || "",
                    url: item.url || "",
                    username: item.username || "",
                };
            }
        });
        setForm(transformedForm)
    }
    const addPlatformMore = () => {
        const newPlatform = {
            software_name: "other_platforms",
            url: "",
            username: "",
            password: "",
            name: ""
        };
        setForm((prevForm) => ({
            ...prevForm,
            other_platforms: [...prevForm.other_platforms, newPlatform],
        }));
    };

    const handleInputChange = (e, software, key) => {
        setForm((prevForm) => ({
            ...prevForm,
            [software]: {
                ...prevForm[software],
                [key]: e.target.value
            }
        }));
    };
    const removePlatform = async (index, otherPlatformId) => {
        const confirmationMessage = await Swal.fire({
            title: "Are you sure?",
            text: "You can't revert this!",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: '#1E6FE0',
            cancelButtonColor: '#dc3545',
            confirmButtonText: "Yes, Remove!",
            cancelButtonText: "Cancel",
            dangerMode: true,
        });
        if(confirmationMessage.isConfirmed){
            if(otherPlatformId) {
                removeBillingDetail(otherPlatformId, 'remove_logins', false)
            }
            const updatedPlatforms = form.other_platforms.filter(
                (_, i) => i !== index
            );
            setForm((prevForm) => ({
                ...prevForm,
                other_platforms: updatedPlatforms,
            }));
        }
    };
    const handleSave = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('billing_group_id', form.billing_group_id);
        formData.append('form', JSON.stringify(form));
        await saveLoginDetails(formData);
        setForm({...{
                billing_group_id: "",
                ehr_access: {},
                practice_management_access: {},
                clearing_house: {},
                other_platforms: [],
            }})
    };
    console.log('billingLists.practiceList', billingLists.practiceList)

    return (
        <>
            <div className="card-header py-0 m-0 d-flex justify-content-between headerBgImg fw-semibold fs-8 text-uppercase align-middle align-items-center">
                <h5 className="text-white pt-xl-3 pt-lg-2 pb-xl-1 pb-lg-0">Login Details</h5>
            </div>
            <div className="card-body rounded-bottom-5 bgGray border-top-0 border-top-right-0 border-top-left-0">
                {form.billing_group_id==='' ? (
                    <div className='p-3 cursor-pointer'>
                        {billingLists.practiceList.map((item, key)=>{
                            return (
                                <div key={key} onClick={() => setForm({ ...form, billing_group_id: item.billing_group_id })}  className="d-flex align-items-center justify-content-between bg-white rounded-4 mt-3 text-start py-2 px-3 widthDesktopMobile">
                                    <Form.Check onClick={(e)=>{
                                        setForm({...form, billing_group_id: e.target.value})
                                    }} className="form-control border-0 text-start text-dark" key={key} name={'billing_group_id'} type={'radio'} id={`default-radio`} value={item.billing_group_id} label={item?.legal_name ? item?.legal_name : item.practice_name}/>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <form onSubmit={(e) => handleSave(e)}>
                        <Row>
                            <h6 className="header-text text-uppercase">Electronic Health Record (EHR) System Access</h6>
                            {["name", "url", "username", "password"].map((field, index) => (
                                <Col md={3} key={index}>
                                    {field === "password" ? (
                                        <div className="form-group mb-3 align-items-center d-flex justify-content-between billingPassword">
                                            <input type={!showPassword ? "password" : "text"} required
                                                   placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                                                   className="form-control mt-2"
                                                   value={form.ehr_access[field] || ""}
                                                   onChange={(e) => handleInputChange(e, "ehr_access", field)}
                                            />
                                            <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                                               onClick={() => setShowPassword(!showPassword)}
                                            ></i>
                                        </div>
                                    ) : (
                                        <div className="form-group mb-3">
                                            <input type="text" required
                                                   placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                                                   className="form-control mt-2"
                                                   value={form.ehr_access[field] || ""}
                                                   onChange={(e) => handleInputChange(e, "ehr_access", field)}
                                            />
                                        </div>
                                    )}
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <h6 className="header-text text-uppercase">Practice Management Software</h6>
                            {["name", "url", "username", "password"].map((field, index) => (
                                <Col md={3} key={index}>
                                    {field === "password" ? (
                                        <div className="form-group mb-3 align-items-center d-flex justify-content-between billingPassword">
                                            <input type={!showPassword ? "password" : "text"} required
                                                   placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                                                   className="form-control mt-2"
                                                   value={form.practice_management_access[field] || ""}
                                                   onChange={(e) => handleInputChange(e, "practice_management_access", field)}
                                            />
                                            <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                                               onClick={() => setShowPassword(!showPassword)}
                                            ></i>
                                        </div>
                                    ) : (
                                        <div className="form-group mb-3">
                                            <input type="text" placeholder={field.charAt(0).toUpperCase() + field.slice(1)} required
                                                   className="form-control mt-2" value={form.practice_management_access[field] || ""}
                                                   onChange={(e) => handleInputChange(e, "practice_management_access", field)}
                                            />
                                        </div>
                                    )}
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <h6 className="header-text text-uppercase">Clearing House</h6>
                            {["name", "url", "username", "password"].map((field, index) => (
                                <Col md={3} key={index}>
                                    {field === "password" ? (
                                        <div className="form-group mb-3 align-items-center d-flex justify-content-between billingPassword">
                                            <input required
                                                   type={!showPassword ? "password" : "text"}
                                                   placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                                                   className="form-control mt-2"
                                                   value={form.clearing_house[field] || ""}
                                                   onChange={(e) => handleInputChange(e, "clearing_house", field)}
                                            />
                                            <i
                                                className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                                                onClick={() => setShowPassword(!showPassword)}
                                            ></i>
                                        </div>
                                    ) : (
                                        <div className="form-group mb-3">
                                            <input required type="text"
                                                   placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                                                   className="form-control mt-2"
                                                   value={form.clearing_house[field] || ""}
                                                   onChange={(e) => handleInputChange(e, "clearing_house", field)}
                                            />
                                        </div>
                                    )}
                                </Col>
                            ))}
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="d-flex">
                                    <h6 className="header-text text-uppercase">Other Platforms</h6>
                                    <button type="button" className="btn btn-primary btn-sm py-0 px-2 ms-2 rounded-2" onClick={addPlatformMore}>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        {form?.other_platforms?.map((platform, index) => (
                            <Row key={index}>
                                {["name", "url", "username", "password"].map((field, idx) => (
                                    <Col md={3} key={idx}>
                                        {field === "password" ? (
                                            <Row>
                                                <Col md={11} className="pe-0">
                                                    <div className="form-group mb-3 align-items-center d-flex justify-content-between billingPassword">
                                                        <input required type={!showPassword ? "password" : "text"}
                                                               placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                                                               className="form-control mt-2"
                                                               value={platform[field] || ""}
                                                               onChange={(e) => {
                                                                   const updatedPlatforms = [...form.other_platforms];
                                                                   updatedPlatforms[index][field] = e.target.value;
                                                                   setForm((prevForm) => ({
                                                                       ...prevForm,
                                                                       other_platforms: updatedPlatforms
                                                                   }));
                                                               }}
                                                        />
                                                        <i
                                                            className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        ></i>
                                                    </div>
                                                </Col>
                                                <Col md={1} className="d-flex align-items-center justify-content-end ms-0">
                                                    <button type="button" className="btn btn-danger btn-sm rounded-2"
                                                            onClick={() => removePlatform(index, platform?.id, platform?.billing_group_id)}>
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <div className="form-group mb-3">
                                                <input type="text" required
                                                       placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                                                       className='form-control mt-2'
                                                       value={platform[field] || ""}
                                                       onChange={(e) => {
                                                           const updatedPlatforms = [...form.other_platforms];
                                                           updatedPlatforms[index][field] = e.target.value;
                                                           setForm((prevForm) => ({
                                                               ...prevForm,
                                                               other_platforms: updatedPlatforms
                                                           }));
                                                       }}
                                                />
                                            </div>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        ))}
                        <Row>
                            <Col md={12} className="d-flex justify-content-end gap-2">
                                <button type="submit" className="btn btn-primary">Save</button>
                            </Col>
                        </Row>
                    </form>
                )}
            </div>
        </>
    );
};