import React from "react";
import {Link} from "react-router-dom";

export const CredentialingFinancial = ({userDashboardData}) => {
    return (
        <div className="card-body mb-3">
            <Link to="/invoice" className="text-reset text-decoration-none">
                <div>
                    Total Amount: <span
                    className="float-end">${userDashboardData?.payable_amount?.payable_amount || 0}</span>
                </div>
                <hr/>
                <div>
                    Paid Amount: <span className="float-end">${userDashboardData?.paid_amount?.paid_amount || 0}</span>
                </div>
                <hr/>
                <div>
                    Remaining Amount: <span
                    className="float-end">${parseInt(userDashboardData?.payable_amount?.payable_amount) - parseInt(userDashboardData?.paid_amount?.paid_amount) || 0}</span>
                </div>
            </Link>
        </div>
    )
}
