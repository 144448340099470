import { KTSVG } from "./KTSVG";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../login/context/AuthContext";
import {Link} from "react-router-dom";

export const HeaderTitle = ({ title = '', dispatchBy = '', back=()=>{}}) => {
    const { user, setUser } = useContext(AuthContext);
    const [localUser, setLocalUser] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('userLogin');
        setLocalUser(storedUser ? JSON.parse(storedUser) : null);
    }, []);

    useEffect(() => {
        setUser(localUser);
    }, [localUser, setUser]);

    return (
        <div className="topHeader d-flex bgGray rounded-4 align-items-center py-2 px-4 justify-content-between">
            <h4 className="header-text headerFsize p-0 text-uppercase">
                {dispatchBy == 'userDetail' &&
                    <Link to={{pathname: '/user-details'}} className="text-decoration-none mt-0" title="go to back">
                        <KTSVG path="/svg/backarrow.svg" className="fs-3 mt-2 fw-bold me-2" />
                    </Link>}
                {dispatchBy == 'billingDetail' &&
                    <span onClick={(() => {back()})} className="text-decoration-none mt-0 cursor-pointer" title="go to back Billing Detail View">
                        <KTSVG path="/svg/backarrow.svg" className="fs-3 mt-2 fw-bold me-2" />
                    </span>}
                {title}
            </h4>
            <div className="d-flex flex-column align-items-end d-none d-md-block">
                <h4 className="header-text headerFsize p-0">
                    <KTSVG path="/svg/userwithbg.svg" className="fs-3 mt-2 fw-bold me-2" />
                    {user?.full_name || localUser?.full_name}
                </h4>
                <span className="fs-15 businessNameStyle themeBlueClr"> {user?.business_name && `(${localUser?.business_name})`}</span>
            </div>
        </div>
    );
};
