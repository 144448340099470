import React, {
    useContext,
    useEffect,
    useState
} from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { BillingContext } from "../../context/BillingContext";
import {array} from "prop-types";

export const Step2CreateProviderDetails = () => {
    const { saveProviderDetail, billingLists, formContext, } = useContext(BillingContext);
    const animatedComponents = makeAnimated();
    const [providerDetails, setProviderDetails] = useState({
        provider_name: "",
        provider_phone: "",
        provider_email: "",
        provider_npi: "",
        provider_taxonomy: "",
        provider_dob: "",
        home_address: "",
        billing_group_location: [],
    });
    const [errors, setErrors] = useState({});
    const handleProviderChange = (e) => {
        const { name, value } = e.target;
        setProviderDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    useEffect(() => {
        if (formContext) {
            let selectedLocations = [];
            for(let i=0; i < billingLists.practiceLocationList.length; i++){
                if(formContext.billing_group_location_ids.search(billingLists.practiceLocationList[i].billing_group_location_id) !== -1){
                    selectedLocations.push({value:billingLists.practiceLocationList[i].billing_group_location_id, label:billingLists.practiceLocationList[i].location_name})
                }
            }
            setProviderDetails({
                provider_name: formContext.provider_name || "",
                provider_phone: formContext.provider_phone || "",
                provider_email: formContext.provider_email || "",
                provider_npi: formContext.provider_npi || "",
                provider_taxonomy: formContext.provider_taxonomy || "",
                provider_dob: formContext.provider_dob || "",
                home_address: formContext.home_address || "",
                provider_id: formContext.provider_id || "",
                billing_group_location: selectedLocations,
            });
        }
    }, [formContext]);
    // Handle multi-select changes
    const handleMultiSelectChange = (selectedOptions) => {
        setProviderDetails((prevState) => ({
            ...prevState,
            billing_group_location: selectedOptions || [],
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!providerDetails.provider_name.trim()) newErrors.provider_name = "Provider Name is required.";
        if (!providerDetails.provider_phone.trim()) newErrors.provider_phone = "Provider Phone is required.";
        if (!providerDetails.provider_email.trim() || !/\S+@\S+\.\S+/.test(providerDetails.provider_email)) {
            newErrors.provider_email = "Valid Email is required.";
        }
        if (!providerDetails.provider_npi.trim()) newErrors.provider_npi = "NPI is required.";
        if (!providerDetails.provider_taxonomy.trim()) newErrors.provider_taxonomy = "Specialty is required.";
        if (!providerDetails.provider_dob.trim()) newErrors.provider_dob = "Date of Birth is required.";
        if (!providerDetails.billing_group_location.length) {
            newErrors.billing_group_location = "At least one location must be selected.";
        }
        return newErrors;
    };

    const handleSave = async () => {
        const newErrors = validateForm();
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            let locations = [];
            for(let i=0; i < providerDetails.billing_group_location.length; i++){
                locations.push(providerDetails.billing_group_location[i].value);
            }
            providerDetails.billing_group_location = locations.join(',');
            saveProviderDetail(providerDetails);
        }
    };

    return (
        <>
            <div className="card-header py-0 m-0 d-flex justify-content-between headerBgImg fw-semibold fs-8 text-uppercase align-middle align-items-center">
                <h5 className="text-white pt-xl-3 pt-lg-2 pb-xl-1 pb-lg-0">Provider Details</h5>
            </div>
            <div className="card-body rounded-bottom-5 bgGray border-top-0 border-top-right-0 border-top-left-0">
                <div className="card border-0 mb-4 bg-transparent">
                    <div>
                        <Row className="px-4 py-2">
                            <Col md={6}>
                                <div className="form-group mb-3">
                                    <label htmlFor="provider_name">
                                        Provider Name <span className="text-primary fw-bold">*</span>
                                    </label>
                                    <input
                                        id="provider_name"
                                        name="provider_name"
                                        placeholder="Provider Name"
                                        className={`form-control ${errors.provider_name ? "is-invalid" : ""}`}
                                        value={providerDetails.provider_name}
                                        onChange={handleProviderChange}
                                    />
                                    {errors.provider_name && (
                                        <div className="invalid-feedback">{errors.provider_name}</div>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group mb-0">
                                    <label htmlFor="billing_group_location">
                                        Billing Group Location <span className="text-primary fw-bold">*</span>
                                    </label>
                                    <Select isMulti
                                            className={`mt-1 p-0 h-20px ${errors.billing_group_location ? "is-invalid" : ""}`}
                                            components={animatedComponents}
                                            placeholder="Select Locations"
                                            value={providerDetails.billing_group_location}
                                            onChange={handleMultiSelectChange}
                                            options={billingLists?.practiceLocationList?.map((list) => ({
                                                value: list.billing_group_location_id,
                                                label: list.location_name,
                                            }))}
                                    />
                                    {errors.billing_group_location && (
                                        <div className="invalid-feedback">{errors.billing_group_location}</div>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group mb-3">
                                    <label htmlFor="provider_phone">
                                        Provider Phone <span className="text-primary fw-bold">*</span>
                                    </label>
                                    <input
                                        id="provider_phone"
                                        name="provider_phone"
                                        placeholder="Provider Phone"
                                        className={`form-control ${errors.provider_phone ? "is-invalid" : ""}`}
                                        value={providerDetails.provider_phone}
                                        onChange={handleProviderChange}
                                    />
                                    {errors.provider_phone && (
                                        <div className="invalid-feedback">{errors.provider_phone}</div>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group mb-3">
                                    <label htmlFor={`provider_email`}>
                                        Email <span className="text-primary fw-bold">*</span>
                                    </label>
                                    <input
                                        id={`provider_email`}
                                        name="provider_email"
                                        placeholder="Provider Email"
                                        className={`form-control ${errors.provider_email ? 'is-invalid' : ''}`}
                                        value={providerDetails.provider_email || ''}
                                        onChange={handleProviderChange}
                                    />
                                    {errors.provider_name && (
                                        <div className="invalid-feedback">{errors.provider_name}</div>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group mb-3">
                                    <label htmlFor={`provider_taxonomy`}>
                                        Specialty <span className="text-primary fw-bold">*</span>
                                    </label>
                                    <input
                                        id={`provider_taxonomy`}
                                        name="provider_taxonomy"
                                        placeholder="Specialty"
                                        className={`form-control ${errors.provider_taxonomy ? 'is-invalid' : ''}`}
                                        value={providerDetails.provider_taxonomy || ''}
                                        onChange={handleProviderChange}
                                    />
                                    {errors.provider_name && (
                                        <div className="invalid-feedback">{errors.provider_name}</div>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group mb-3">
                                    <label htmlFor={`provider_npi`}>
                                        NPI <span className="text-primary fw-bold">*</span>
                                    </label>
                                    <input
                                        id={`provider_npi`}
                                        name="provider_npi"
                                        placeholder="NPI"
                                        className={`form-control ${errors.provider_npi ? 'is-invalid' : ''}`}
                                        value={providerDetails.provider_npi || ''}
                                        onChange={handleProviderChange}
                                    />
                                    {errors.provider_name && (
                                        <div className="invalid-feedback">{errors.provider_name}</div>
                                    )}
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group mb-3">
                                    <label htmlFor={`provider_dob`}>
                                        DOB <span className="text-primary fw-bold">*</span>
                                    </label>
                                    <input
                                        id={`provider_dob`}
                                        name="provider_dob"
                                        type="date"
                                        placeholder="DOB"
                                        className={`form-control ${errors.provider_dob ? 'is-invalid' : ''}`}
                                        value={providerDetails.provider_dob || ''}
                                        onChange={handleProviderChange}
                                    />
                                    {errors.provider_name && (
                                        <div className="invalid-feedback">{errors.provider_name}</div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row className="d-flex align-items-center px-4">
                            <Col md={12}>
                                <div className="form-group mb-3">
                                    <label htmlFor="home_address">Address</label>
                                    <input
                                        id="home_address"
                                        name="home_address"
                                        placeholder="Address"
                                        className={`form-control ${errors.home_address ? "is-invalid" : ""}`}
                                        value={providerDetails.home_address}
                                        onChange={handleProviderChange}
                                    />
                                    {errors.home_address && (
                                        <div className="invalid-feedback">{errors.home_address}</div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row>
                    <Col md={12} className="d-flex justify-content-end gap-2 pt-4">
                        <button type="button" className="btn btn-primary" onClick={handleSave}>
                            Save
                        </button>
                    </Col>
                </Row>
            </div>
        </>
    );
};
