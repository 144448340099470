import axios from "../../../axios_handler";
const {REACT_APP_BACKEND_API_URL} = process.env;
const GET_BILLING_REPORT = `${REACT_APP_BACKEND_API_URL}get_billing_report`;

const getBillingReportRequest = async (data) => {
    try {
        const d = await axios
            .post(`${GET_BILLING_REPORT}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in removeCustomerDocsData : ", error);
        throw error;
    }
}

export {getBillingReportRequest}