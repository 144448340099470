import React, {useState, useEffect, useContext} from 'react';
import ReactApexChart from 'react-apexcharts';
import { Loader } from '../../general/Loader';
import {formatDateDMY, getMonthShortName} from "../../general/utils";
import {DashboardContext} from "../context/DashboardContext";

const MonthlyStatsChart = () => {
    const{billingDashboardData, chartAllData, claimsAndPaymentReport, selectedMonth, paymentDate} = useContext(DashboardContext);
    const [insuranceNames, setInsuranceNames] = useState([]);
    const [insurancePaid, setInsurancePaid] = useState([]);
    const [top5TotalCharges, setTop5TotalCharges] = useState(0);

    const data=chartAllData?.topPaidInsurances || [];
    const allPaidInsurances= claimsAndPaymentReport?.payments
    const topPaidInsurancesLoading = false;

    useEffect(() => {
        // Extract and update insurance data when the `data` prop changes
        const names = [];
        const paid = [];
        let totalCharges = 0;
        // is_credit = 0 its means amount with minus symbol so it should be minus like total current amount -munus amount

        totalCharges = allPaidInsurances?.length > 0 ? allPaidInsurances?.reduce((acc, item) => {
            const amount = parseFloat(item?.amount || 0)
            if (item?.is_credit == 0) {
                // Subtract if is_credit is 0
                return acc - Math.abs(amount);
            } else {
                return acc + amount;
            }
        }, 0) : data?.reduce( (acc, item) => item?.paid ? acc + parseFloat(item?.paid) : acc, 0);
        setTop5TotalCharges(totalCharges);
        for (let i = 0; i < data.length && i < 5; i++) {
            //name only 16 characters shows other ....
            names.push(data[i]?.payment_from?.length > 17 ? data[i]?.payment_from?.substring(0, 16) + '...' : data[i]?.payment_from);
            //names.push(data[i]?.payment_from);
            paid.push(parseFloat(data[i]?.paid));
            totalCharges += parseFloat(data[i]?.paid);
        }

        setInsuranceNames(names);
        setInsurancePaid(paid);

    }, [data]);

    const chartData = {
        series: [{
            name: 'Total Revenue',
            data: insurancePaid
        }],
        options: {
            chart: {
                type: 'bar',
                height: 800
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            dataLabels: {
                enabled: false,
                formatter: function (val) {
                    return `$ ${val.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`;
                },
                style: {
                    fontSize: '12px',
                    fontWeight: '600',
                    colors: ['#fff']
                }
            },
            xaxis: {
                categories: insuranceNames,
                labels: {
                    style: {
                        fontSize: '10px',
                    }
                }
            },
            yaxis: {
                title: {
                    text: 'Revenue',
                    style: {
                        fontFamily: 'inherit',
                    }
                },
                labels: {
                    formatter: (val) => `$ ${val.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`
                }
            },
            title: {
                text: 'Top Paid Insurances',
                align: 'center',
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return `$ ${val.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}`;
                    }
                },
                x: {
                    formatter: function (val, opts) {
                        const fullInsuranceName = data[opts.dataPointIndex]?.payment_from;
                        return `${fullInsuranceName} (${data[opts.dataPointIndex]?.claims})`;
                    }
                }
            },
            colors: ['#4A7EFF', '#32CD32', '#FF4500', '#FFD700', '#1E90FF'],
        }
    };

    return (
        <div>
            {topPaidInsurancesLoading ? (
                <Loader />
            ) : (
                <div>
                    <h4 className="ms-3 col-lg-6 mb-4 revenueMonthlyStat z-index-1">{paymentDate?.from ? paymentDate?.from : selectedMonth && selectedMonth} - Revenue: $ {top5TotalCharges.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}</h4>
                    <ReactApexChart
                        options={chartData.options}
                        series={chartData.series}
                        type="bar"
                        height={300}
                    />
                </div>
            )}
        </div>
    );
};

export default MonthlyStatsChart;
