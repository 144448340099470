import {createContext, useEffect, useState} from "react"
import {getUserDashboardReq, getBillingDashboardReq, getBillingTopInsuranceReq, getPaymentByFilterDateReq, getSelectedMonthBillingDashboardReq} from "./_request"
import {format, parseISO} from "date-fns";
import {customAlert} from "../../general/helpers";

export const DashboardContext = createContext();
export const DashboardContextProvider = ({children}) => {

    const [loading, setLoading] = useState(true);
    const [individualLoader, setIndividualLoader] = useState({monthly: false, weekly: false, payment: false});
    const [userDashboardData, setUserDashboardData] = useState([]);
    const [customerType, setCustomerType] = useState('');
    const [billingDashboardData, setBillingDashboardData] = useState([]);
    const [claimsAndPaymentReport, setClaimsAndPaymentReport] = useState({weeklyClaims: [], monthlyClaims: [], payments: []});
    const [chartAllData, setChartAllData] = useState({monthlyStats: [], weeklyStats: [], topPaidInsurances: []});
    const [reportingDate, setReportingDate] = useState([]);
    const [showPaymentReport, setShowPaymentReport] = useState(false);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [reportingTitle, setReportingTitle] = useState('');
    const [paymentDate, setPaymentDate] = useState({from: null, to: null});
    const [error, setError] = useState(null);

    const getUserDashboard = async () => {
        setLoading(true);
        const response = await getUserDashboardReq();
        if (response.status.toLowerCase() === 'success') {
            setUserDashboardData(response);
            setCustomerType(response?.customer_type);
            setLoading(false);
        } else {
            setUserDashboardData([]);
            setLoading(false);
        }
        setLoading(false);
    };
//Billing Dashboard
    const getBillingDashboardData = async () => {
        setLoading(true);
        const response = await getBillingDashboardReq();
        if (response?.status?.toLowerCase() === 'success') {
            setBillingDashboardData(response);
            setChartAllData({monthlyStats: response?.monthly, weeklyStats: response?.weekly, topPaidInsurances: response?.top_five_insurances});
            setClaimsAndPaymentReport({weeklyClaims: response?.billing_latest_weekly_data ?? [], monthlyClaims: response?.billing_latest_monthly_data ?? [], payments: response?.payments ?? []});
            setLoading(false);
        } else {
            setBillingDashboardData([]);
            setClaimsAndPaymentReport({weeklyClaims: [], monthlyClaims: [], payments: []});
            setChartAllData({monthlyStats: [], weeklyStats: [], topPaidInsurances: []});
            setLoading(false);

        }

        setLoading(false);
    }

    const getBillingTopInsurances = async () => {
        const response = await getBillingTopInsuranceReq();
        if (response?.status?.toLowerCase() === 'success') {
            return response;
        } else {
            return [];

        }
    }

    const getSelectedMonthBillingDashboardData = async (month, year) => {
       return await getSelectedMonthBillingDashboardReq(month, year);
    }

    const getPaymentByFilterDate = async (startDate, endDate) => {
        return await getPaymentByFilterDateReq(startDate, endDate);
    }

    // handle and show claims states by request
    const handleClaimStat = (requestBy) => {
        setShowPaymentReport(false);
        if(requestBy === 'weekly') {
            setReportingTitle('Weekly Claims');
            setReportingDate(claimsAndPaymentReport?.weeklyClaims);
        }
        if(requestBy === 'monthly') {
            setReportingTitle('Monthly Claims');
            setReportingDate(claimsAndPaymentReport?.monthlyClaims);
        }
        if(requestBy === 'payments') {
            setReportingTitle('Monthly');
            setShowPaymentReport(true);
            setReportingDate(claimsAndPaymentReport?.payments);
        }
    }
    const handleClaimsByMonth = async (step, byMonth) => {
        setIndividualLoader({monthly: true, weekly: false, payment: true});
        const currentDate = new Date(`${byMonth} 1, ${selectedYear}`);
        if (step === 'prev') {
            currentDate.setMonth(currentDate.getMonth() - 1);
        } else if (step === 'next') {
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
        const updatedMonth = currentDate.toLocaleString("default", { month: "short" });
        const updatedYear = currentDate.getFullYear();
        setSelectedMonth(updatedMonth);
        setSelectedYear(updatedYear);
        //
        if (step && updatedMonth) {
            const response = await getSelectedMonthBillingDashboardReq(updatedMonth.toLowerCase(), updatedYear);
            setPaymentDate({from: null, to: null});
            setError(null);
            setError('');
            if(response?.status?.toLowerCase() === 'success') {
                setClaimsAndPaymentReport((prev) => ({...prev, monthlyClaims: response?.monthly_submissions ?? [], payments: response?.all_paid_insurance ?? []}));
                setChartAllData((prev) => ({...prev, monthlyStats: response?.monthly ?? [], topPaidInsurances: response?.top_five_insurances ?? []}));
                setIndividualLoader({weekly: false, monthly: false, payment: false});
            }
        }
        setIndividualLoader({weekly: false, monthly: false, payment: false});
    }
    const handleFilterByDate = async (dateState) => {
        if (!dateState.startDate && !dateState.endDate) {
            setError('Please select start date and end date');
            return;
        }
        try {
            setIndividualLoader({payment: true});
            let startDate, endDate;
            let formattedStartDate, formattedEndDate;
                startDate = typeof dateState.startDate === 'string'
                    ? parseISO(dateState.startDate)
                    : dateState.startDate;

                endDate = typeof dateState.endDate === 'string'
                    ? parseISO(dateState.endDate)
                    : dateState.endDate;

            const startMonth = startDate.toLocaleString("default", { month: "long" });
            const endMonth = endDate.toLocaleString("default", { month: "long" });
            if (startMonth === endMonth) {
                setPaymentDate({ from: startMonth, to: startMonth });
            } else {
                setPaymentDate({ from: startMonth, to: endMonth });
            }
                formattedStartDate = format(startDate, 'yyyy-MM-dd');
                formattedEndDate = format(endDate, 'yyyy-MM-dd');
            setError(null);
            const response = await getPaymentByFilterDate(formattedStartDate, formattedEndDate);
            if (response?.status?.toLowerCase() === 'success') {
                setChartAllData((prevData) => ({
                    ...prevData,
                    topPaidInsurances: response?.top_five_insurances,
                }));
                setClaimsAndPaymentReport((prevData) => ({ ...prevData, payments: response?.all_paid_insurance ?? []}));
            } else {
                customAlert(response?.result, 'error');
            }
        } catch (error) {
            console.error('Error parsing dates:', error);
            setError('Invalid date format');
        } finally {
            setIndividualLoader({payment: false});
        }
    };


    return (
        <DashboardContext.Provider value={{
            loading,
            userDashboardData,
            getUserDashboard,
            customerType,
            getBillingDashboardData,
            billingDashboardData,
            getBillingTopInsurances,
            getSelectedMonthBillingDashboardData,
            getPaymentByFilterDate,
            handleClaimStat,
            reportingDate,
            showPaymentReport,
            handleClaimsByMonth,
            chartAllData,
            individualLoader,
            claimsAndPaymentReport,
            setSelectedMonth,
            setSelectedYear,
            selectedMonth,
            selectedYear,
            handleFilterByDate,
            error,
            reportingTitle,
            paymentDate
        }}>
            {children}
        </DashboardContext.Provider>
    )
}
