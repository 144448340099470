import React, {useContext, useEffect, useState} from 'react'
import {KTSVG} from "../../general/KTSVG";
import {HeaderTitle} from "../../general/HeaderTitle";
import {BillingViewDropdownMenu} from "./components/BillingViewDropdownMenu";
import {BillingContext} from "../context/BillingContext";

export const BillingDetailsView = () => {
    const {billingDetail, billingLists, editBillingDetail} = useContext(BillingContext);
    const [activeDropdown, setActiveDropdown] = useState(null);
    const [boxHeights, setBoxHeights] = useState(0);
    const toggleDropdown = (id) => {
        setActiveDropdown((prev) => (prev === id ? null : id));
    };
    useEffect(() => {
        const totalHeight = window.innerHeight;
        const groupsHeight = 380;
        const remainingHeight = totalHeight - groupsHeight;
        setBoxHeights(remainingHeight);
    }, []);
    return (
        <>
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-12">
                        <HeaderTitle title="Billing Details"/>
                    </div>
                </div>
                <div className="billing-details-container">
                    <div className="row mt-4 group-provider-section">
                        <div className="col-md-6">
                            <div className="card bg-transparent rounded-5 border-top-0">
                                <div className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center">
                                    <h4 className="text-white pt-2 fs-5 text-uppercase ps-3">Groups</h4>
                                    <div className="btn" onClick={() => editBillingDetail(null, 'createPractice')}><span className="text-primary f-w500"><KTSVG path="/svg/plusbtn.svg" /> </span></div>
                                </div>
                                <div className="card-body">
                                    <div className="user-info-list pe-2">
                                        {billingLists?.practiceList?.map((item, index) => (
                                            <div key={index} className="d-flex align-items-center justify-content-between bg-gray-list mt-3 text-start py-2 px-3 w-100">
                                                <span className="f-w500 cursor-pointer">{item?.legal_name} - {item?.practice_npi}</span>
                                                <div>
                                            <span className="float-end align-middle align-items-center mtMinus5">
                                                <KTSVG path="/svg/forward.svg" className="fs-3 mt-2 fw-bold me-2"/>
                                            </span>
                                                    <span
                                                        onClick={() => toggleDropdown('practice' + item?.billing_group_id)}
                                                        className="float-end cursor-pointer align-middle align-items-center align-items-center me-3">
                                                <i className="fas fa-ellipsis-v fs-4 mtgVertical align-items-center align-middle"></i>
                                            </span>
                                                    {activeDropdown === 'practice' + item?.billing_group_id && (
                                                        <BillingViewDropdownMenu item={item} dispatchBy="practice"/>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3 mt-md-0">
                            <div className="card bg-transparent rounded-5 border-top-0">
                                <div
                                    className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center">
                                    <h4 className="text-white pt-2 fs-5 text-uppercase ps-3">Providers</h4>
                                    <div className="btn" onClick={() => editBillingDetail(null, 'createProvider')}><span className="text-primary f-w500"><KTSVG path="/svg/plusbtn.svg"/> </span></div>
                                </div>
                                <div className="card-body">
                                    <div className="user-info-list pe-2">
                                        {billingLists?.providerList?.map((item, index) => (
                                            <div key={index} className="d-flex align-items-center justify-content-between bg-gray-list mt-3 text-start py-2 px-3 w-100">
                                                <span className="f-w500 cursor-pointer">{item.provider_name} - {item.provider_npi}</span>
                                                <div>
                                                    <span className="float-end align-middle cursor-pointer align-items-center mtMinus5">
                                                        <KTSVG path="/svg/forward.svg"
                                                               className="fs-3 mt-0 fw-bold me-2 "/>
                                                    </span>
                                                    <span onClick={() => toggleDropdown('provider'+item?.provider_id)}
                                                          className="float-end cursor-pointer align-middle align-items-center align-items-center me-3">
                                                        <i className="fas fa-ellipsis-v fs-4 mtgVertical align-items-center align-middle"></i>
                                                    </span>
                                                    {activeDropdown === 'provider'+item?.provider_id && (
                                                        <BillingViewDropdownMenu item={item} dispatchBy="provider"/>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4 login-credentials-documents">
                        <div className="col-md-6 mt-3 mt-md-0 login-credentials">
                            <div className="card bg-transparent rounded-5 border-top-0">
                                <div
                                    className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center">
                                    <h4 className="text-white pt-2 fs-5 text-uppercase ps-3">Login Credentials</h4>
                                    <div className="btn" onClick={() => editBillingDetail(Math.random(), 'createLogin')}><span className="text-primary f-w500"><KTSVG path="/svg/plusbtn.svg"/> </span></div>
                                </div>
                                <div className="card-body">
                                    <div className="billing-info-list pe-2" style={{maxHeight: `${boxHeights}px`,overflowY: 'scroll'}}>
                                        {billingDetail?.map((item, index) => {
                                            if(item.billing_logins.length>0) {
                                                return(
                                                    <div onClick={() => editBillingDetail({billing_group_id:item.billing_group_id}, 'createLogin')} key={index} className="d-flex align-items-center cursor-pointer justify-content-between bg-gray-list mt-3 text-start py-2 px-3 w-100">
                                                        <span className="f-w500 cursor-pointer">{item?.legal_name} - {item.billing_logins.length} Logins</span>
                                                        <div>
                                                            <span className="float-end align-middle align-items-center mtMinus5">
                                                                <KTSVG path="/svg/forward.svg" className="fs-3 mt-2 fw-bold me-2"/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-5 mt-md-0 documents">
                            <div className="card bg-transparent rounded-5 border-top-0">
                                <div
                                    className="card-header card-header-style py-0 m-0 d-flex justify-content-between fw-semibold fs-8 headerBgImg text-uppercase align-middle align-items-center">
                                    <h4 className="text-white pt-2 fs-5 text-uppercase ps-3">Documents</h4>
                                    <div className="btn" onClick={() => editBillingDetail(Math.random(), 'uploadDocument')}><span className="text-primary f-w500"><KTSVG path="/svg/plusbtn.svg"/> </span></div>
                                </div>
                                <div className="card-body">
                                    <div className="billing-info-list pe-2" style={{maxHeight: `${boxHeights}px`,overflowY: 'scroll'}}>
                                        {billingDetail?.map((item, index) => {
                                            if(item.billing_documents.length>0) {
                                                return(
                                                    <div onClick={() => editBillingDetail({billing_group_id:item.billing_group_id}, 'uploadDocument')} key={index} className="d-flex align-items-center cursor-pointer justify-content-between bg-gray-list mt-3 text-start py-2 px-3 w-100">
                                                        <span className="f-w500 cursor-pointer">{item?.legal_name} - {item.billing_documents.length} Documents</span>
                                                        <div>
                                                            <span className="float-end align-middle align-items-center mtMinus5">
                                                                <KTSVG path="/svg/forward.svg" className="fs-3 mt-2 fw-bold me-2"/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}