import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import PrivateRoute from "../routes/PrivateRoute";
import LoginForm from "../login/LoginForm";
import React, {useContext} from "react";
import {AuthContext} from "../login/context/AuthContext";
import {ResetPassword} from "../login/ResetPassword";
import {InvoicePayByEmail} from "../invoices/InvoicePayByEmail";
import {InvoiceContextProvider} from "../invoices/context/InoviceContext";
import DashboardBilling from "../dashboard/DashboardBilling";

export let AppNavigation = () => {
    let {auth, user} = useContext(AuthContext);
    return (
        <BrowserRouter>
            <Routes>
                {auth ? (
                    <>
                        <Route path="/*" element={<PrivateRoute />} />
                        {user?.customer_type == 'billing' ? (
                            <Route index element={<Navigate to={'/dashboard-billing'} />} />
                        ) : (
                            <Route index element={<Navigate to={'/dashboard'} />} />
                        )}
                    </>
                ) : (
                    <>
                        <Route path="reset-password/:email/:token" element={<ResetPassword />} />
                        <Route path="invoice-pay/:token" element={<InvoiceContextProvider><InvoicePayByEmail /></InvoiceContextProvider>} />
                        <Route path="login" element={<LoginForm />} />
                        <Route index element={<Navigate to="/login" />} />
                        <Route path="/*" element={<Navigate to="/login" />} />
                    </>
                )}
            </Routes>
        </BrowserRouter>
    )
}