import React, {useContext, useEffect, useState} from "react";
import {Row, Col, Form} from "react-bootstrap";
import axios from "../../../../axios_handler";
import {customAlert} from "../../../general/helpers";
import {BillingContext} from "../../context/BillingContext";

const {REACT_APP_BACKEND_API_URL} = process.env;

export const Step4UploadDocuments = () => {
    const { removeBillingDetail, billingDetail, billingLists, formContext, backHome, getBillingDetail } = useContext(BillingContext);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({ insurance_docs: [] });
    const [status, setStatus] = useState("idle");
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        if (formContext && Object.keys(formContext).length > 0) {
            setForm({
                ...formContext,
                insurance_docs: [],
            });
        }
    }, [formContext]);

    const handleSave = async () => {
        const newErrors = {};
        if (!form.billing_docs_name) {
            newErrors.billing_docs_name = "File Name is required";
        }
        if (form?.insurance_docs?.length === 0) {
            newErrors.insurance_docs = "Please Select File";
        }
        if (!form?.billing_group_id) {
            newErrors.billing_group_id = "Please Select Practice";
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        setErrors({});
        setStatus("uploading");
        const formData = new FormData();
        form?.insurance_docs.forEach((doc, index) => {
            formData.append(`insurance_docs[${index}]`, doc);
        });
        formData.append('action', 'uploaded_documents');
        formData.append('billing_group_id', form?.billing_group_id);
        formData.append('file_name', form.billing_docs_name);
        try {
            const response = await axios.post(
                `${REACT_APP_BACKEND_API_URL}update_billing_billing_docs`,
                formData,
                {
                    headers: { "Content-Type": "multipart/form-data" },
                    onUploadProgress: (progressEvent) => {
                        const progress = progressEvent.total
                            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            : 0;
                        setUploadProgress(progress);
                    },
                }
            );
            if (response.status === 200) {
                setStatus("success");
                getBillingDetail();
                handleReset();
                backHome();
            } else {
                setStatus("error");
            }
        } catch (error) {
            console.error('Error uploading documents:', error);
            customAlert('An error occurred while uploading documents', 'error');
        }
    };

    const removeFile = (index) => {
        const updatedDocs = form.insurance_docs.filter((_, i) => i !== index);
        setForm((prevForm) => ({
            ...prevForm,
            insurance_docs: updatedDocs,
        }));
    };

    const getFileIcon = (fileName) => {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        switch (fileExtension) {
            case 'pdf':
                return process.env.PUBLIC_URL + "/icons/pdf.png";
            case 'doc':
            case 'docx':
                return process.env.PUBLIC_URL + "/icons/doc.png";
            case 'xls':
            case 'xlsx':
            case 'csv':
                return process.env.PUBLIC_URL + "/icons/excel.png";
            default:
                return process.env.PUBLIC_URL + "/icons/image.png";
        }
    };

    const handleReset = () => {
        setForm({ insurance_docs: [] });
        setErrors("");
        document.getElementById("insurance_docs").value = null;
        document.getElementById("billing_docs_name").value = "";
        setForm({ billing_docs_name: "" });
        setForm({ billing_group_id: "" });
        setUploadProgress(0);
        setStatus("idle");
    };
    const downloadFile = (url) => {
        const link = process.env.REACT_APP_BACKEND_URL + 'public/' + url;
        window.open(link, '_blank');
    };
    const hasContent = form?.billing_docs_name || form?.insurance_docs?.length > 0;
    console.log('billingDetail sdfs', billingDetail)
    return (
        <>
            <div className="card-header py-0 m-0 d-flex justify-content-between headerBgImg fw-semibold fs-8 text-uppercase align-middle align-items-center">
                <h5 className="text-white pt-xl-3 pt-lg-2 pb-xl-1 pb-lg-0">Upload Documents</h5>
            </div>
            <div className="card-body rounded-bottom-5 bgGray border-top-0 border-top-right-0 border-top-left-0">
                {!form?.billing_group_id || form.billing_group_id==='' ? (
                    <div className='p-3'>
                        {billingLists.practiceList.map((item, key)=>{
                            return (
                                <div key={key} onClick={()=> setForm({ ...form, billing_group_id: item.billing_group_id })} className="d-flex align-items-center justify-content-between bg-white rounded-4 mt-3 text-start py-2 px-3 widthDesktopMobile">
                                    <Form.Check onClick={(e)=>{
                                        setForm({...form, billing_group_id: e.target.value})
                                    }} key={key} name={'billing_group_id'}  className="form-control border-0 text-start text-dark" type={'radio'} id={`default-radio`} value={item.billing_group_id} label={item.legal_name ? item.legal_name : item.practice_name}/>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <Row>
                        <Col md={10}>
                            <label htmlFor="license_expiry">Upload Documents</label>
                        </Col>
                        <Col md={3}>
                            <div className="form-group mt-2">
                                <input type="text" id="billing_docs_name"
                                       name="billing_docs_name" placeholder="File Name"
                                       value={form?.billing_docs_name || ""}
                                       onChange={(e) =>
                                           setForm((prevForm) => ({
                                               ...prevForm,
                                               billing_docs_name: e.target.value,
                                           }))
                                       }
                                       className={`form-control ${errors?.billing_docs_name ? "is-invalid" : ""}`}
                                       style={{padding:"0.6rem", fontSize:12}}
                                />
                                {errors?.billing_docs_name && <div className="invalid-feedback">{errors?.billing_docs_name}</div>}
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="form-group mt-2">
                                <input type="file" multiple id="insurance_docs" name="insurance_docs"
                                       onChange={(e) => {
                                           const selectedFiles = Array.from(e.target.files);
                                           setForm((prevForm) => ({
                                               ...prevForm,
                                               insurance_docs: [
                                                   ...(prevForm.insurance_docs || []),
                                                   ...selectedFiles,
                                               ],
                                           }));
                                       }}
                                       className={`form-control ${errors?.insurance_docs ? "is-invalid" : ""}`}
                                       style={{padding:"0.6rem", fontSize:12}}                            />
                                {errors?.insurance_docs && <div className="invalid-feedback">{errors?.insurance_docs}</div>}
                            </div>
                        </Col>
                        {hasContent && (
                            <Col md={2} className="pt-1">
                                <button type="button" style={{padding:"1rem", fontSize:12}} className="btn btn-primary me-4" onClick={handleSave}>
                                    Upload <i className="fa fa-upload"></i>
                                </button>
                                <button type="button" style={{padding:"1rem", fontSize:12}} className="btn btn-warning" onClick={handleReset}>
                                    Reset <i className="fa fa-refresh"></i>
                                </button>
                            </Col>
                        )}
                        {(status === 'uploading' || status === 'success') && (
                            <div className="space-y-3">
                                <div className="progress">
                                    <div className={`progress-bar progress-bar-striped progress-bar-animated ${status === 'success' ? 'bg-success' : 'bg-primary'} `}
                                         role="progressbar" style={{ width: `${uploadProgress}%`, height: '10px' }} aria-valuenow={uploadProgress} aria-valuemin="0" aria-valuemax="100">
                                        {uploadProgress}%
                                    </div>
                                </div>
                            </div>
                        )}
                        <Col md={12}>
                            {form?.insurance_docs?.map((file, index) => (
                                <div key={index} className="m-1 mt-3 btn-sm btn downloadBtn">
                                    <div className="position-relative">
                                        <i onClick={() => removeFile(index)} className="fa fa-times fs-7 text-danger background-transparent border-0 position-absolute top-0 end-0"></i>
                                        <img src={getFileIcon(file.name)} alt="image" className="me-2" style={{ height: 35, width: 35 }}/>
                                        <div className="f-w500">{file.name}</div>
                                        <div className="fs-9">
                                            {((file.size / 1024)/1024).toFixed(2)}MB
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Col>
                        <div>
                            {billingDetail?.length > 0 && (
                                <>
                                    <h5 className="mt-4">Document List</h5>
                                    {billingDetail.map((item) => {
                                        if(item.billing_group_id==form?.billing_group_id){
                                            return item.billing_documents.map((file,index)=> {
                                                return(
                                                    <div key={'doc' + index} className="d-flex align-items-center justify-content-between bg-white rounded-3 mt-3 text-start py-2 px-3 w-50">
                                                        <div className="cursor-pointer" onClick={() => downloadFile(file?.document_file)}>
                                                            <img src={getFileIcon(file.document_file)} alt="image" className="me-3" style={{
                                                                height: 35,
                                                                width: 35
                                                            }}/>
                                                            <span className="f-w500">{file.file_name}</span>
                                                        </div>
                                                        <button className="btn btn-danger btn-sm fw-600" onClick={()=>{removeBillingDetail(file.id, 'remove_document')}}>Remove</button>
                                                    </div>
                                                )
                                            })
                                        }
                                    })}
                                </>
                            )}
                        </div>
                    </Row>
                )}
            </div>
        </>
    );
};
