import React from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';

const DateRangePicker = ({ dateState, setDateState, is_error }) => {
    return (
        <div className="date-range-picker myDateRangePicker">
            <Flatpickr
                value={[dateState.startDate, dateState.endDate]}
                onChange={([startDate, endDate]) => setDateState({startDate, endDate})}
                options={{mode: "range"}}
                className={`form-control fs-8 h-40px rounded-0 ${is_error ? 'is-invalid' : ''}`}
                placeholder='Pick date'
            />
            <span className="calendar-icon">
                <i className="fa fa-calendar"></i>
            </span>
            {is_error && <span className="invalid-feedback">{is_error}</span>}
        </div>
    );
};

export default DateRangePicker;