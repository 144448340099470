import {createContext, useEffect, useState} from "react"
import {getBillingReportRequest} from "./_request"

export const BillingReportContext = createContext()
export const BillingReportContextProvider = ({children}) => {
    const [loading, setLoading] = useState(false)
    const [reportData, setReportData] = useState(null)

    const getBillingReport = async (data) => {
        setLoading(true)
        const response = await getBillingReportRequest(data)
        if (response?.status?.toLowerCase() === 'success') {
            setReportData(response?.result);
            setLoading(false);
        } else {
            setLoading(false);
        }

        setLoading(false);
    }

    return (
        <BillingReportContext.Provider value={{isLoading: loading, getBillingReport , reportData}}>
            {children}
        </BillingReportContext.Provider>
    )
}
