import axios from 'axios';
// Create axios instance with base url and credentials support
export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    withCredentials: false,
});

// Request interceptor. Runs before your request reaches the server
const onRequest = (config) => {
    config.headers.apitoken = localStorage.getItem('AuthToken')
    return config;
}
// Error interceptor.when an error response
const onError = (error) => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('AuthToken');
        localStorage.removeItem('userLogin');
        // window.location.reload();
    }
    return Promise.reject(error);
};
// attach your interceptor
axiosInstance.interceptors.request.use(onRequest, null);
axiosInstance.interceptors.response.use((response) => response, onError);

export default axiosInstance;
