const AUTH_LOCAL_STORAGE_KEY = 'AuthToken';
export function setupAxios(axios) {
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
        (config) => {
            const auth = getAuth()
            if (auth) {
                config.headers.Authorization = `Bearer ${auth}`
            }
            return config
        },
        (err) => Promise.reject(err)
    )
}

export const getAuth = () => {

    if (!localStorage) {
        return
    }
    const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    if (!lsValue) {
        return
    }
    try {
        const auth = JSON.parse(lsValue);
        if (auth) {
            return auth;
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
    }
}

export const setAuth = (auth) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

export const loginUser = (user) => {
    if (!localStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(user);
        localStorage.setItem('userLogin', lsValue);
    } catch (error) {
        console.error('User LOCAL STORAGE SAVE ERROR', error);
    }
}

export const removeAuth = () => {
    if (!localStorage) {
        return
    }

    try {
        localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
        localStorage.removeItem('userLogin');
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
    }
}

