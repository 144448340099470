import ReadMoreReact from "read-more-react";
import {formatDateDMY} from "../../general/utils";
import React from "react";

export const CredentialingAppUpdates = ({appUpdates}) => {
    return (
        <div className="card-body">
            <table className="table dashboardUpdateTbl">
                <thead className="tblGrayClr bg-gray py-4 fs-8 my-5">
                <tr className="update-table-th">
                    <th className="text-theme-active ps-2">Application Name</th>
                    <th className="text-theme-active">Status</th>
                    <th className="text-theme-active">Last Message</th>
                    <th className="text-theme-active">Date</th>
                </tr>
                </thead>
                <tbody>
                {appUpdates?.map((item, index) => (
                    <tr key={index} className="data-table-row" style={{backgroundColor: '#F5F6F8'}}>
                        <td className="ps-2">{item?.credential?.form_type === 'credentialing_agencies' ? item.credential?.group_name : item.credential?.provider_name} ({item.insurance?.title})</td>
                        <td className={`clr${item.insurance_status}`}>
                            {item.insurance_status}
                            <div
                                className={item?.is_application_paid == 1 ? 'text-success' : 'text-danger'}>{item?.is_application_paid == 1 ? '(Paid)' : ''}</div>
                        </td>
                        <td>
                            {item?.insurance_timeline?.length > 0 ? (
                                <ReadMoreReact
                                    text={item?.insurance_timeline?.[0]?.message}
                                    min={30}
                                    ideal={40}
                                    readMoreText="read more..."
                                />
                            ) : ''}
                        </td>
                        <td>{formatDateDMY(item.last_update_date)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}
