import React, {useContext} from 'react';
import ReactApexChart from 'react-apexcharts';
import {truncateCharacterLimit} from "../../general/utils";
import {DashboardContext} from "../context/DashboardContext";

export const BillingWeeklyPieDonut = ({legendOptions={} }) => {
    const{chartAllData} = useContext(DashboardContext);

    const colors = ['#4A7EFF','#F2C63F','#ff4560','#30752b'];
    const labels = ['Submission', 'Resubmission', 'Rejection'];
    const data = [chartAllData?.weeklyStats?.submission, chartAllData?.weeklyStats?.re_submission, chartAllData?.weeklyStats?.rejection];

    const chartData = {
        series: data || [],
        options: {
            plotOptions: {
                pie: {
                    donut: {
                        size: '60%',
                    },
                },
            },
            labels: labels || [],
            colors: colors || ['#4A7EFF', '#F2C63F', '#47CB47', '#FF5253'],
            dataLabels: {
                enabled: false,
                formatter: function (val, opts) {
                    return opts?.w?.globals?.series[opts.seriesIndex] || '';
                },
                background: {
                    enabled: true,
                    borderRadius: 4,
                },
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 1,
                    left: 1,
                    blur: 2,
                },
            },
            legend: {
                show: true,
                formatter: function (val, opts) {
                    const seriesIndex = opts.seriesIndex;
                    const label = labels[seriesIndex];
                    const value = opts?.w?.globals?.series[seriesIndex] || '';
                    return `${truncateCharacterLimit(label, 15, false)}: ${value}`;
                },
                // onHover: {
                //     highlightDataSeries: true,
                // },
                position: 'bottom',
                horizontalAlign: 'left',
                floating: false,
                fontSize: '14px',
                width: '100%',
                display: 'grid',
                top: 0,
                marginTop: 1,
                paddingBottom: 0,
                offsetX: 0,
                offsetY: legendOptions?.topY || 0,
                labels: {
                    colors: ['#333'],
                },
                markers: {
                    width: 10,
                    height: 10,
                    radius: 3,
                },
            },
            chart: {
                height: 'auto'
            },
        },
    };

    return (
        <div className="wrapper p-0">
            <div className="d-flex flex-column justify-content-center flex-row-fluid p-0 mb-0 pieChartCredentialing">
                <ReactApexChart options={chartData?.options} series={chartData?.series} type="donut" height={350} />
            </div>
            <div className="d-flex flex-column d-none justify-content-center flex-row-fluid pe-11 mb-5">
                {labels.map((label, index) => (
                    <div key={index} className="d-flex fs-6 fw-semibold align-items-center mb-3">
                        <div className={`bullet bg-${index === 0 ? 'primary' : index === 1 ? 'success' : 'gray-300'} me-3`}></div>
                        <div className="text-gray-500">{label}</div>
                        <div className="ms-auto fw-bold text-gray-700">{chartData.series[index]}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};
