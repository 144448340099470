import React, {useContext} from "react";
import {BillingContext} from "../../context/BillingContext";
export const BillingViewDropdownMenu = ({ item, dispatchBy }) => {
    let {removeBillingDetail, editBillingDetail} = useContext(BillingContext);
    let delAction = () => {
        switch (dispatchBy){
            case 'practice':
                removeBillingDetail(item.billing_group_id, 'remove_practice')
                break
            case 'provider':
                removeBillingDetail(item.provider_id, 'remove_provider_detail')
                break
        }
    }
    return (
        <div className="menu dropdown-menu show position-absolute mt-5 billingDropDown">
            <button className={`dropdown-item ${dispatchBy==='documents' ? 'd-none' : ''}`} onClick={() => editBillingDetail(item, dispatchBy)}>
                Edit
            </button>
            <button className="dropdown-item text-danger" onClick={() => delAction(item, dispatchBy)}>
                Remove
            </button>
        </div>
    )
}
