import React, {useContext, useEffect, useState} from 'react';
import {HeaderTitle} from "../general/HeaderTitle";
import {useLocation} from "react-router-dom";
import {BillingWeeklyPieDonut} from "./components/BillingWeeklyPieDonut";
import {BillingMonthlyPieChart} from "./components/BillingMonthlyPieChart";
import BillingMonthlyStats from "./components/BillingMonthlyStats";
import {BillingDashboardReport} from "./components/BillingDashboardReport";
import {DashboardContext} from "./context/DashboardContext";
import {Loader} from "../general/Loader";
import {BillingDashboardPaymentReport} from "./components/BillingDashboardPaymentReport";
import DateRangePicker from "../general/DateRangePicker";

const DashboardBilling = () => {
    const {pathname} = useLocation();
    const{loading, individualLoader, getBillingDashboardData, handleClaimStat, showPaymentReport, handleClaimsByMonth, setSelectedMonth, setSelectedYear, selectedMonth, selectedYear, handleFilterByDate, error} = useContext(DashboardContext);
    const [dateState, setDateState] = useState({});
    const currentMonth = new Date().toLocaleString("default", { month: "short" });
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        if (pathname === '/dashboard-billing') {
            getBillingDashboardData();
            setSelectedMonth(new Date().toLocaleString('default', { month: 'short' }));
        }
    }, [pathname]);

    return (
        loading ? <Loader/> : (
            <>
                <div className="row mt-4 billingDashboardWrapper">
                    <div className="col-12 mb-4">
                        <HeaderTitle title="Billing Dashboard"/>
                    </div>
                    <div className="col-md-3 mt-3 mt-md-0">
                        <div className="card radius-15 w-100 cursor-pointer" title="Weekly Stat" onClick={() => handleClaimStat('weekly')}>
                            <div className='d-flex justify-content-center mb-0 pb-0 pt-2'>
                                <div className="fs-5 fw-semibold text-gray-500 ps-0 text-start">Weekly Stats</div>
                            </div>
                            <BillingWeeklyPieDonut />
                        </div>
                    </div>
                    <div className="col-md-6 mt-3 mt-md-0">
                        <div className="card radius-15 w-100">
                            {(individualLoader?.payment) && (
                                <div className="loadingOverLay">
                                    <h4>Processing! Please Wait...</h4>
                                </div>
                            )}
                            <div className="d-flex justify-content-end align-items-center mt-2 me-2 zIndex">
                                <DateRangePicker dateState={dateState} setDateState={setDateState} is_error={error} />
                                <button className="btn btn-primary btn-md rounded-0" onClick={() => handleFilterByDate(dateState)}>Apply</button>
                            </div>
                            <div className={`cursor-pointer`} onClick={ () => handleClaimStat('payments')}>
                                <BillingMonthlyStats />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mt-3 mt-md-0">
                        <div className="card radius-15 w-100" title="Monthly Stat">
                            {(individualLoader?.monthly) && (
                                <div className="loadingOverLay">
                                    <h4>Processing! Please Wait...</h4>
                                </div>
                            )}
                            <div className="d-flex justify-content-between mb-0 pb-0 pt-2">
                                <div className="cursor-pointer ms-3" title="Previous Month" onClick={ () => handleClaimsByMonth('prev', selectedMonth)}><i className={`fa fa-left-long fs-3 text-primary`}></i></div>
                                <div className="fs-5 fw-semibold text-center text-gray-500 ps-5 text-start">Monthly Stats <span className="fs-9">({selectedMonth} {selectedYear})</span></div>
                                <div className={`cursor-pointer me-3`} onClick={() => handleClaimsByMonth('next', selectedMonth)}>{(selectedMonth === currentMonth && selectedYear === currentYear) ? '' : (<i className={`fa fa-right-long fs-3 text-primary`} title="Next Month"></i>)}</div>
                            </div>
                            <div className="cursor-pointer" onClick={ () => handleClaimStat('monthly')}>
                                <BillingMonthlyPieChart />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card radius-15 mt-3">
                            {showPaymentReport ? <BillingDashboardPaymentReport  /> : <BillingDashboardReport /> }
                        </div>
                    </div>
                </div>
            </>
        )
    );
};

export default DashboardBilling;