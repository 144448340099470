import React, {useContext, useEffect, useState} from "react";
import {Row, Col} from "react-bootstrap";
import Swal from "sweetalert2";
import {BillingContext} from "../../context/BillingContext";

export const Step1PracticeDetails = () => {
    const { formContext, updateBillingPracticeDetail, removeBillingDetail} = useContext(BillingContext);
    const [practiceDetails, setPracticeDetails] = useState({});
    const [errors, setErrors] = useState({});
    const [locations, setLocations] = useState([])
    useEffect(() => {
        if (formContext) {
            setPracticeDetails(formContext);
            setLocations(formContext.billing_group_location || []);
        } else {
            setPracticeDetails({});
            setLocations([{ location_name: "", location_address: "" }]);
        }
    }, [formContext]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setPracticeDetails((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleSaveAndNext = async () => {
        let newErrors = {};
        if (!practiceDetails.practice_name || practiceDetails.practice_name.trim() === '') {
            newErrors.practice_name = 'Legal Business Name is required';
        }
        // Validate locations
        if (locations?.length === 0) {
            newErrors.locations = "At least one Point of Service is required";
        } else {
            locations?.forEach((location, index) => {
                if (!location.location_name || location.location_name.trim() === "") {
                    newErrors[`location_name_${index}`] = "Location name is required";
                }
                if (!location.location_address || location.location_address.trim() === "") {
                    newErrors[`location_address_${index}`] = "Location address is required";
                }
            });
        }
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        let createdPractice = false;
        if (!formContext) {
            createdPractice = true;
        }
        const updatedLocations = locations.map(location => ({
            ...location,
            billing_group_location_id: location?.billing_group_location_id || null
        }));
        const updatedPracticeDetails = {
            ...practiceDetails,
            billing_group_location: updatedLocations,
            // check if add new group practice or update
            is_created_practice : createdPractice
        };
        updateBillingPracticeDetail(updatedPracticeDetails);
    };
    const handleAddLocation = () => {
        setLocations((prev) => [
            ...prev,
            { location_name: "", location_address: "" },
        ]);
    };
    const handleRemoveLocation = async (index, location) => {
        if (location?.billing_group_location_id) {
            const confirmationMessage = await Swal.fire({
                title: "Are you sure?",
                text: "Are you sure you want to remove this location?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: '#1E6FE0',
                cancelButtonColor: '#dc3545',
                confirmButtonText: "Yes, Remove!",
                cancelButtonText: "Cancel",
                dangerMode: true,
            });
            if(confirmationMessage.isConfirmed){
                removeBillingDetail(location.billing_group_location_id, 'remove_group_location', false);
                setLocations((prev) => prev.filter((_, idx) => idx !== index));
            }
        } else {
            setLocations((prev) => prev.filter((_, idx) => idx !== index));
        }
    };
    const handleLocationChange = (index, field, value) => {
        setLocations((prev) =>
            prev.map((loc, idx) =>
                idx === index ? { ...loc, [field]: value } : loc
            )
        );
    };
    return (
        <>
            <div className="card-header py-0 m-0 d-flex justify-content-between headerBgImg fw-semibold fs-8 text-uppercase align-middle align-items-center">
                <h5 className="text-white pt-xl-3 pt-lg-2 pb-xl-1 pb-lg-0">Practice Details</h5>
            </div>
            <div className="card-body rounded-bottom-5 bgGray border-top-0 border-top-right-0 border-top-left-0">
                <div className="billingGroupDetail">
                    <Row>
                        {/* Legal Information Section */}
                        <h6 className="header-text text-uppercase"> Legal Information</h6>
                        <Col md={6}>
                            <div className="form-group mb-3">
                                <label htmlFor='practice_name'>Legal Business Name <span
                                    className="text-primary fw-bold">*</span></label>
                                <input
                                    id='practice_name'
                                    name='practice_name'
                                    placeholder="Legal Business Name"
                                    className={`form-control ${errors.practice_name ? 'is-invalid' : ''}`}
                                    value={practiceDetails?.practice_name || ''}
                                    onChange={handleChange}/>
                                {errors.practice_name &&
                                    <div className="invalid-feedback">{errors.practice_name}</div>}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group mb-3">
                                <label htmlFor='ein_tin'>Tax Identification Number (TIN)</label>
                                <input
                                    id='ein_tin'
                                    name='ein_tin'
                                    placeholder="Tax Identification Number (TIN)"
                                    className={`form-control ${errors.ein_tin ? 'is-invalid' : ''}`}
                                    value={practiceDetails?.ein_tin}
                                    onChange={handleChange}/>
                                {errors.ein_tin && <div className="invalid-feedback">{errors.ein_tin}</div>}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group mb-3">
                                <label htmlFor='practice_npi'>Group NPI</label>
                                <input
                                    type='number'
                                    name='practice_npi'
                                    placeholder="Group NPI"
                                    id='practice_npi'
                                    className={`form-control ${errors.practice_npi ? 'is-invalid' : ''}`}
                                    value={practiceDetails?.practice_npi}
                                    onChange={handleChange}
                                />
                                {errors.practice_npi && <div className="invalid-feedback">{errors.practice_npi}</div>}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group mb-3">
                                <label htmlFor='medicareProviderNumber'>Medicare Group Number</label>
                                <input
                                    id='medicare_id'
                                    name='medicare_id'
                                    placeholder="Medicare Group Number"
                                    className={`form-control ${errors.medicare_id ? 'is-invalid' : ''}`}
                                    value={practiceDetails?.medicare_id}
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group mb-3">
                                <label htmlFor='medicaidProviderNumber'>Medicaid Group Number</label>
                                <input
                                    id='medicaid_id'
                                    name='medicaid_id'
                                    placeholder="Medicaid Group Number"
                                    className={`form-control ${errors.medicaid_id ? 'is-invalid' : ''}`}
                                    value={practiceDetails?.medicaid_id}
                                    onChange={handleChange}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group mb-3">
                                <Row>
                                    <Col md={6}>
                                        <label htmlFor='stateLicense'>State License</label>
                                        <input
                                            id='license_number'
                                            name='license_number'
                                            placeholder="State License"
                                            className={`form-control ${errors.license_number ? 'is-invalid' : ''}`}
                                            value={practiceDetails?.license_number}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <label htmlFor='licenseExpiry'>Expiry</label>
                                        <input
                                            type='date'
                                            name='license_expiry'
                                            id='license_expiry'
                                            className={`form-control ${errors.license_expiry ? 'is-invalid' : ''}`}
                                            value={practiceDetails?.license_expiry}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex mt-2 mb-4 align-items-center align-content-center">
                                <h6 className="header-text text-uppercase pt-3">Point of Services</h6>
                                <button type='button' className='btn btn btn-primary ms-4' onClick={handleAddLocation}> Add More</button>
                            </div>
                        </Col>
                        {locations?.map((location, index) => (
                            <Col md={8} key={index}>
                                <div className="form-group mb-3">
                                    <Row>
                                        <Col md={4}>
                                            <input
                                                name={`location_name_${index}`}
                                                placeholder="Name"
                                                className={`form-control mb-md-0 mb-2 ${
                                                    errors[`location_name_${index}`] ? "is-invalid" : ""
                                                }`}
                                                value={location?.location_name}
                                                onChange={(e) =>
                                                    handleLocationChange(index, "location_name", e.target.value)
                                                }
                                            />
                                            {errors[`location_name_${index}`] && (
                                                <div className="invalid-feedback">
                                                    {errors[`location_name_${index}`]}
                                                </div>
                                            )}
                                        </Col>
                                        <Col md={7}>
                                            <input
                                                name={`location_address_${index}`}
                                                placeholder="Address"
                                                className={`form-control mb-md-0 mb-2 ${
                                                    errors[`location_address_${index}`] ? "is-invalid" : ""
                                                }`}
                                                value={location?.location_address}
                                                onChange={(e) =>
                                                    handleLocationChange(index, "location_address", e.target.value)
                                                }
                                            />
                                            {errors[`location_address_${index}`] && (
                                                <div className="invalid-feedback">
                                                    {errors[`location_address_${index}`]}
                                                </div>
                                            )}
                                        </Col>
                                        <Col md={1}>
                                            <button type="button" className="btn btn-danger btn-sm mt-1"
                                                    onClick={() => handleRemoveLocation(index, location)}>
                                                Remove
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        ))}
                        {errors.locations && (<div className="text-danger mb-3">{errors.locations}</div>)}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="form-group mb-3">
                                <div>
                                    <button type="button" className="btn btn-primary btn-md float-end mt-2 mx-1" onClick={handleSaveAndNext}>Save</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}