import axios from "../../../axios_handler";

const {REACT_APP_BACKEND_API_URL} = process.env;
const GET_BILLING_DETAIL = `${REACT_APP_BACKEND_API_URL}get_billing_details`;
const UPDATE_BILLING_LOGIN = `${REACT_APP_BACKEND_API_URL}update_billing_logins_details`;
const REMOVE_BILLING_DETAIL = `${REACT_APP_BACKEND_API_URL}remove_billing_details_new`;

export const saveLoginDetailsReq = async (data) => {
    try {
        const d = await axios.post(`${REACT_APP_BACKEND_API_URL}save_login_credentials`, data);
        return d.data;
    } catch (error) {
        console.error("Error in addBillingDetailReq : ", error);
        throw error;
    }
}

export const getBillingDetailReq = async () => {
    try {
        const d = await axios.get(`${GET_BILLING_DETAIL}`);
        return d.data;
    } catch (error) {
        console.error("Error in getBillingDetailReq : ", error);
        throw error;
    }
}

export const removeBillingDetailReq = async (data) => {
    try {
        const d = await axios.post(`${REMOVE_BILLING_DETAIL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in removeBillingDetailReq : ", error);
        throw error;
    }
}

export const updateBillingLoginReq = async (data) => {
    try {
        const d = await axios.post(`${UPDATE_BILLING_LOGIN}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in updateBillingLoginReq : ", error);
        throw error;
    }
}

export const addProviderDetailReq = async (data) => {
    try {
        const d = await axios.post(`${REACT_APP_BACKEND_API_URL}add_billing_provider_details`, data);
        return d.data;
    } catch (error) {
        console.error("Error in addProviderDetailReq : ", error);
        throw error;
    }
}

export const updateProviderDetailReq = async (data) => {
    try {
        const d = await axios.post(`${REACT_APP_BACKEND_API_URL}update_billing_provider_details`, data);
        return d.data;
    } catch (error) {
        console.error("Error in updateProviderDetailReq : ", error);
        throw error;
    }
}

export const updateBillingPracticeDetailReq = async (data) => {
    try {
        const d = await axios.post(`${REACT_APP_BACKEND_API_URL}update_billing_practice_details`, data);
        return d.data;
    } catch (error) {
        console.error("Error in updateBillingPracticeDetailReq : ", error);
        throw error;
    }
}