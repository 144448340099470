import {PieDonutChart} from "../../general/PieDonutChart";
import React from "react";

export const CredentialingPieDonutGraph = ({userDashboardData}) => {
    const statusCounts = {
        paid: 0,
        pending: 0,
        submitted: 0,
        approved: 0,
        contracted: 0,
        outofservice: 0,
        marketclosed: 0
    };
    let totalAppCount = 0;
    userDashboardData && userDashboardData.app_stats &&
    userDashboardData?.app_stats.forEach((app_stat) => {
        totalAppCount += app_stat.count;
        const status = app_stat.insurance_status.toLowerCase();
        const normalizedStatus = status.replace(/\s+/g, '');
        // Check status is present
        if (statusCounts.hasOwnProperty(normalizedStatus)) {
            statusCounts[normalizedStatus] += parseInt(app_stat.count, 10);
        } else {
            statusCounts[normalizedStatus] = parseInt(app_stat.count, 10);
        }
    });
    return (
        <div className="card-body">
            <PieDonutChart
                data={Object.values(statusCounts)}
                labels={['Paid', 'Pending', 'Submitted', 'Approved', 'Contracted', 'Out of Service', 'Market Closed']}
                colors={['#014d01', '#ffc300', '#1E6FE0', '#008000', '#8032e6', '#c81824', '#ff0000']}
                legendOptions={{topY: 0}}
                total_app={parseInt(userDashboardData.total_app)}
                paid_app={parseInt(userDashboardData?.total_paid_app)}
                dispatchBy="app_status"
                height="700"
                pieWidth="65"
                title="Application Status"
            />
        </div>
    )
}
