import React, {useState} from 'react';

export const CustomPagination = ({totalRecords, recordsPerPage, onPageChange}) => {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(totalRecords / recordsPerPage);
    const updatePage = (e, page) => {
        e.preventDefault();
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
            onPageChange(page);
        }
    };

    const renderPageNumbers = () => {
        let startPage = 0
        const pageNumbers = [];
        if (currentPage <= 2) {
            startPage = 1
        } else {
            startPage = Math.min(totalPages, currentPage - 2);
        }
        const endPage = Math.min(totalPages, currentPage + 2);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={`paginate_button page-item ${currentPage === i ? 'active' : ''}`}>
                    <button onClick={(e) => updatePage(e, i)} className='page-link'>{i}</button>
                </li>
            );
        }
        return pageNumbers;
    };
    return (
        <div className='row'>
            <div
                className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div
                className='col-sm-12 col-md-12 d-flex1 align-items-center1 justify-content-center1 justify-content-md-end1'>
                <div id='kt_table_users_paginate'>
                    <ul className='pagination zkPagination d-flex justify-content-center pt-3'>

                        {/* First Page Button */}
                        <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button onClick={(e) => updatePage(e, 1)} className='page-link'>First</button>
                        </li>

                        {/* Previous Page Button */}
                        <li className={`paginate_button page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button onClick={(e) => updatePage(e, currentPage - 1)} className='page-link'>Previous
                            </button>
                        </li>

                        {/* Page Numbers */}
                        {renderPageNumbers()}

                        {/* Next Page Button */}
                        <li className={`paginate_button page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button onClick={(e) => updatePage(e, currentPage + 1)} className='page-link'>Next</button>
                        </li>

                        {/* Last Page Button */}
                        <li className={`paginate_button page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button onClick={(e) => updatePage(e, totalPages)} className='page-link'>Last</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
