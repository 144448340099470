import {createContext, useEffect, useState} from "react";
import {forgottenPasswordData, loginReq, logOutReq, resetPasswordLinkData, resetLoginPasswordData, AddNewCustomerData, invoicePayLinkVerifyData, getUserAfterLoginReq} from "./_request";
import {getAuth, loginUser, removeAuth, setAuth as setAuthUser} from "../../../helpers/myHelpers";
import {customAlert} from "../../general/helpers";

export const AuthContext = createContext();
export const AuthContextProvider = ({children}) => {
    let [auth, setAuth] = useState(false);
    const [user, setUser] = useState(null);
    let [loading, setLoading] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        // todo: check if user is logged in
        getUserAfterLogin();
        // if(getAuth()!=null){
        //     setAuth(true)
        //     //setUser(true)
        // }

        // const events = ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'];
        // events.forEach(event => {
        //     window.addEventListener(event, handleUserActivity);
        // });
        // resetTimeout();

        // check login status from server

        // return () => {
        //     events.forEach(event => {
        //         window.removeEventListener(event, handleUserActivity);
        //     });
        //     clearTimeout(timeoutId);
        // };
    }, []);
    const getUserAfterLogin = async () => {
        setLoading(true);
        try {
            const response = await getUserAfterLoginReq();
            if (response.status && response.status.toLowerCase() === 'success') {
                setAuth(true);
                setUser(response.user);
            } else {
                console.error('Error: Unsuccessful response', response);
                // customAlert('Failed to fetch user data', 'error');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            // customAlert('An error occurred while fetching user data', 'error');
        } finally {
            setLoading(false);
        }
    };
    const resetTimeout = () => {
        clearTimeout(timeoutId);
        // Timeout after 4 hours
        const newTimeoutId = setTimeout(logOut, 4*60*60*1000);
        setTimeoutId(newTimeoutId);
    };
    const handleUserActivity = () => {
        resetTimeout();
    };
    const login = async (email,password) => {
        setLoading(true)
        let data = await loginReq(email,password)
        if(data.status.toLowerCase() === 'success'){
            // saving to local storage
            setAuthUser(data.token);
            setUser(data.user);

            loginUser(data.user);
            setAuth(true)
            setLoading(false)
        }else{
            customAlert('Credential Not Matched!', 'error');
            setLoading(false)
        }
        setLoading(false)
    };

    const logOut = async () => {
        setLoading(true)
        let data = await logOutReq();
        removeAuth();
        setUser(null);
        setAuth(false);
        setLoading(false);
    };
    const forgottenPassword = async (email) => {
        return await forgottenPasswordData(email);
    }
    const resetPasswordLinkVerify = async (email, token) => {
        return await resetPasswordLinkData(email, token);
    }
    const resetLoginPassword = async (email, token, password) => {
        return await resetLoginPasswordData(email, token, password);
    }
    const AddNewCustomer = async (data) => {
        return await AddNewCustomerData(data);
    }
    const invoicePayLinkVerify = async (token) => {
        return await invoicePayLinkVerifyData(token);
    }
    return (
        <AuthContext.Provider value={{
            loading,
            auth,
            user,
            setUser,
            login,
            logOut,
            forgottenPassword,
            resetPasswordLinkVerify,
            resetLoginPassword,
            AddNewCustomer,
            invoicePayLinkVerify
        }}>
            {children}
        </AuthContext.Provider>
    )
}
