import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import './loginForm.css'
import {AuthContext} from "./context/AuthContext";
import {Loader} from "../general/Loader";
import {customAlert} from "../general/helpers";
import ReCAPTCHA from "react-google-recaptcha";

export const ResetPassword = () => {
    const {resetPasswordLinkVerify, resetLoginPassword} = useContext(AuthContext);
    const { email, token } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [errorCaptcha, setErrorCaptcha] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        if(email && token){
            setLoading(true);
            resetPasswordLinkVerify(email, token).then(res => {
                if(res.status.toLowerCase() === 'success'){
                    setLoading(false);
                }else {
                    let data = res?.message;
                    navigate('/login', {state: {data}});
                    setLoading(false);
                }
            });
        }
        setLoading(false);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
       if(!isVerified) {
           setErrorCaptcha('Captcha is Required!');
            return;
        }
        setLoading(true);
        try {
            const response = await resetLoginPassword(email, token, newPassword);
            if (response.status.toLowerCase() === 'success') {
               customAlert('Password Reset Successfully', 'success');
                navigate('/login');
            } else {
                setError(response.message);
            }
        } catch (error) {

            setError('An error occurred. Please try again later.');
        }
        setLoading(false);
    };
    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };
    const handleCaptchaChange = (response) => {
        if (response) {
            setIsVerified(true);
        }
    };
    return (
        <div className='container-fluid vh-100'>
            <div className='vh-100'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="d-flex justify-content-center align-items-center mt-5 mt-md-0 h-100 login-form-sec">
                            {loading ? <Loader /> : (
                            <form className='form w-75 mt-5 mt-md-0' onSubmit={handleSubmit}>
                                <div className='text-center mb-11'>
                                    <img className='h-75px mb-3 d-md-none d-lg-none d-sm-block' alt='Logo' src={process.env.PUBLIC_URL + '/Logo_new.svg'} style={{ height: '125px' }} />
                                    <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
                                </div>
                                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                                <div className='fv-row mb-8'>
                                    <label className='form-label fs-6 fw-bolder text-dark'>New Password</label>
                                    <div className="align-items-center d-flex form-control justify-content-between">
                                        <input
                                            id='new_password'
                                            type={showNewPassword ? 'text' : 'password'}
                                            className={`custom-input-pass border-0 w-100`}
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            required
                                        />
                                        <i className={showNewPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} onClick={toggleShowNewPassword} >
                                        </i>
                                    </div>
                                </div>
                                <div className='fv-row mb-3 mt-2'>
                                    <label htmlFor='confirm_password' className="f-w600 mb-2">Confirm New Password</label>
                                    <div className="align-items-center d-flex form-control justify-content-between">
                                        <input
                                            id='confirm_password'
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            className={`custom-input-pass border-0 w-100`}
                                            required
                                        />
                                        <i className={showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} onClick={toggleShowConfirmPassword}>
                                        </i>
                                    </div>
                                </div>
                                <div className='fv-row mb-3 mt-2'>
                                    <div className={`d-flex justify-content-center ${errorCaptcha ? 'is-invalid' : ''}`}>
                                        <ReCAPTCHA
                                            sitekey="6LfuNqApAAAAABGz_949SdMD8p4GhBlgwc6rUUWx"
                                            onChange={handleCaptchaChange}
                                            style={{ transform: 'scale(0.8)', transformOrigin: '0 0' }}
                                        />
                                    </div>
                                    {errorCaptcha && <span className="invalid-feedback fw-bold">{errorCaptcha}</span>}
                                </div>
                                <div className='d-grid mb-10'>
                                    <button type='submit' id='kt_sign_in_submit' className='btn btn-primary' disabled={!isVerified}>
                                        <span className='indicator-label'>Save</span>
                                    </button>
                                </div>
                                {/* end::Action */}
                            </form>
                            )}
                        </div>
                    </div>
                    <div
                        className='col-6 text-center vh-100 text-sec-login d-none d-md-block' style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/login-page-bg.webp'})`}}>
                        <div className='d-flex flex-column h-100 justify-content-center flex-center py-15 px-5 px-md-15 w-100'>
                            <Link to='/' className='mb-12'>
                                <img alt='Logo' src={process.env.PUBLIC_URL + '/Logo_new.svg'} style={{ height: '125px' }} className='h-75px mb-3'/>
                            </Link>
                            <h1 className='text-white fs-2qx fw-bolder text-center my-3'>
                                Welcome to Atlantis Revenue Cycle Management
                            </h1>
                            <div className='text-white text-center'>
                                <p>In this kind of post, introduces a person they’ve interviewed <br/> and provides some background information
                                    about and their <br/> work following this is a transcript of the interview.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
