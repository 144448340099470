import axios from "../../../axios_handler";
const {REACT_APP_BACKEND_API_URL} = process.env;
const LOGIN_URL = REACT_APP_BACKEND_API_URL+"login";
const LOG_OUT_URL = REACT_APP_BACKEND_API_URL+"logout";
const FORGOTTEN_PASSWORD = `${REACT_APP_BACKEND_API_URL}forgot_password`;
const RESET_PASSWORD_VERIFY = `${REACT_APP_BACKEND_API_URL}reset_password_verify`;
const RESET_LOGIN_PASSWORD = `${REACT_APP_BACKEND_API_URL}reset_login_password`;

let loginReq = async (email, password) => {
    try {
        let data = new FormData();
        data.append('email', email)
        data.append('password', password)
        const d = await axios
            .post(`${LOGIN_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in Login Req() : ", error);
        throw error;
    }
}
let logOutReq = async (email, password) => {
    try {
        let data = new FormData();
        data.append('user', 'test')
        const d = await axios
            .post(`${LOG_OUT_URL}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in logOut Req() : ", error);
        throw error;
    }
}
const forgottenPasswordData = async (email) => {
    try {
        const data = new FormData();
        data.append('email', email)
        const d = await axios
            .post(`${FORGOTTEN_PASSWORD}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in forgottenPasswordData() : ", error);
        throw error;
    }
}
const resetPasswordLinkData = async (email, token) => {
    try {
        const data = new FormData();
        data.append('email', email)
        data.append('token', token)
        const d = await axios
            .post(`${RESET_PASSWORD_VERIFY}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in resetPasswordLinkData() : ", error);
        throw error;
    }
}
const resetLoginPasswordData = async (email, token, password) => {
    try {
        const data = new FormData();
        data.append('email', email)
        data.append('token', token)
        data.append('password', password)
        const d = await axios
            .post(`${RESET_LOGIN_PASSWORD}`, data);
        return d.data;
    } catch (error) {
        console.error("Error in resetLoginPasswordData() : ", error);
        throw error;
    }
}
const AddNewCustomerData = async (data) => {
    try {
        const d = await axios
            .post(`${REACT_APP_BACKEND_API_URL}add_new_customer`, data);
        return d.data;
    } catch (error) {
        console.error("Error in AddNewCustomerData() : ", error);
        throw error;
    }
}
const invoicePayLinkVerifyData = async (token) => {
    try {
        const data = new FormData();
        data.append('token', token)
        const d = await axios
            .post(`${REACT_APP_BACKEND_API_URL}invoice_pay_link_verify`, data);
        return d.data;
    } catch (error) {
        console.error("Error in invoicePayLinkVerifyData() : ", error);
        throw error;
    }
}
const getUserAfterLoginReq = async () => {
    try {
        const d = await axios
            .get(`${REACT_APP_BACKEND_API_URL}get_user_after_login`);
        return d.data;
    } catch (error) {
        console.error("Error in getUserAfterLoginReq() : ", error);
        throw error;
    }
}

export {loginReq, logOutReq, forgottenPasswordData, resetPasswordLinkData, resetLoginPasswordData, AddNewCustomerData, invoicePayLinkVerifyData, getUserAfterLoginReq}