import React, {useState} from 'react';
import {formatDateDMY} from "../../general/utils";
import {CustomPagination} from "../../general/CustomPagination";
import {NotFound} from "../../general/NotFound";

export const BillingReportTable = ({reportingData = []}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [filter, setFilter] = useState('All'); // State for the filter
    const recordsPerPage = 20;
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const filteredBillingData = filter === 'All' ? reportingData
        : reportingData.filter(billing => billing?.type === filter);
    const currentBillingData = filteredBillingData.slice(indexOfFirstRecord, indexOfLastRecord);
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        setCurrentPage(1);
    };
    return (
        <div className="container-fluid">
            <div className="row my-4">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <h5>Billing Report</h5>
                    <select className="form-select w-auto" onChange={handleFilterChange} value={filter}>
                        <option value="All">All</option>
                        <option value="submission">Submitted</option>
                        <option value="re_submission">Resubmitted</option>
                        <option value="paid">Payment</option>
                        <option value="rejection">Rejected</option>
                    </select>
                </div>
                <div className="col-12 mt-3">
                    <div className="table-responsive-sm">
                        {currentBillingData.length === 0 ? (
                            <div className="alert alert-warning" role="alert">
                                <NotFound message="Data not found for the selected filter." border={false}
                                          fontSize="fs-5"/>
                            </div>
                        ) : (
                            <>
                                <table className="table bg-transparent customTbl border-1">
                                    <thead
                                        className="card-header-style text-white fw-semibold fs-8 text-white headerBgImg text-uppercase align-middle align-items-center">
                                    <tr>
                                        <th className="text-white ps-4">Claim No</th>
                                        <th className="text-white">Insurance</th>
                                        <th className="text-white">Batch Date</th>
                                        <th className="text-white">Service Date</th>
                                        <th className="text-white">Status</th>
                                        <th className="text-white">Charges</th>
                                        {/*<th className="text-white">PTM / Adjustment</th>*/}
                                        {/*<th className="text-white">Adjustment</th>*/}
                                        {/*<th className="text-white">Paid</th>*/}
                                        {/*<th className="text-white">With Held</th>*/}
                                        {/*<th className="text-white">Balance</th>*/}
                                        <th className="text-white">Payment Date</th>
                                    </tr>
                                    </thead>
                                    <tbody className="fs-8">
                                    {currentBillingData.map((billing, index) => (
                                        <tr key={index}
                                            className="align-middle align-items-center data-table-row bg-gray cursorPointer cursor-pointer">
                                            <td className="ps-4">{billing?.claim_no}</td>
                                            <td>{billing?.insurance}</td>
                                            <td>{formatDateDMY(billing?.batch_date)}</td>
                                            <td>{formatDateDMY(billing?.service_date)}</td>
                                            <td>
                                                <span className={`badge ${
                                                    billing?.claim_status === 'paid'
                                                        ? 'bg-success'
                                                        : billing?.claim_status === 'rejected'
                                                            ? 'bg-danger'
                                                            : 'bg-warning-light text-brown-dark'
                                                }`}>
                                                {billing?.claim_status === 'paid' ? 'Paid'
                                                    : billing?.claim_status === 'rejected' ? 'Rejected'
                                                        : 'Pending'}
                                                </span>
                                            </td>
                                            <td>${billing?.charges}</td>
                                            {/*<td>${billing?.pmts_adjs !== null ?  billing?.pmts_adjs : 0} </td>*/}
                                            {/*<td>${billing?.adjustment !== null ?  billing?.adjustment : 0} </td>*/}
                                            {/*<td>${billing?.paid !== null ?  billing?.paid : 0} </td>*/}
                                            {/*<td>${billing?.with_held !== null ?  billing?.with_held : 0} </td>*/}
                                            {/*<td>${billing?.balance !== null ?  billing?.balance : 0} </td>*/}
                                            <td>{formatDateDMY(billing?.modified_on !== null ? billing?.modified_on : billing?.added_on)}</td>

                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                                <CustomPagination totalRecords={filteredBillingData?.length}
                                                  recordsPerPage={recordsPerPage} onPageChange={onPageChange}/>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};