import {Link} from "react-router-dom";
import {HeaderTitle} from "../../general/HeaderTitle";
import React, {useContext, useEffect, useState} from "react";
import {BillingReportContext} from "../context/BillingReportContext";
import {BillingReportTable} from "./BillingReportTable";
import {Loader} from "../../general/Loader";
import {BillingReportForm} from "./BillingReportForm";

export const BillingReport = () => {
    const {isLoading, reportData} = useContext(BillingReportContext);

    return (
        <>
            {
                isLoading ? <Loader/> :
                    <div className="container-fluid">
                        <div className="row mx-0">
                            <div className="col-12 bg-gray radius-15">
                                <Link to={{pathname: '/user-details'}}></Link>
                                <HeaderTitle title="Billing Report" dispatchBy="userDetail"/>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card radius-15 mt-3">

                                        {reportData === null ? <BillingReportForm/> :
                                            (
                                            <div className="card-body">
                                                <div
                                                    className="fs-5 fw-semibold text-center text-gray-500 ps-5 text-start">Billing Report
                                                </div>
                                                <BillingReportTable reportingData={reportData}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
};
