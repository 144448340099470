import React, {useContext, useEffect, useState} from 'react';
import {DashboardContext} from "./context/DashboardContext";
import {Loader} from "../general/Loader";
import {HeaderTitle} from "../general/HeaderTitle";
import {CredentialingAppUpdates} from "./components/CredentialingAppUpdates";
import {CredentialingUserDetail} from "./components/CredentialingUserDetail";
import {CredentialingFinancial} from "./components/CredentialingFinancial";
import {CredentialingPieDonutGraph} from "./components/CredentialingPieDonutGraph";
import {useLocation, useParams} from "react-router-dom";
import {BillingPieDonut} from "./components/BillingPieDonut";
import {BillingAgingSummary} from "./components/BillingAgingSummary";
const Dashboard = () => {
    const {pathname} = useLocation();
    const{loading, userDashboardData, getUserDashboard, customerType} = useContext(DashboardContext)
    const [appUpdates, setAppUpdates] = useState([]);

    useEffect(() => {
        setAppUpdates(userDashboardData?.app_updates);
    }, [userDashboardData?.total_insurances, userDashboardData?.total_app, userDashboardData?.providers, userDashboardData?.practuces]);

    useEffect(() => {
        if (pathname === '/dashboard') {
            getUserDashboard();
        }
    }, [pathname]);
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <div className="row mt-4">
                        <div className="col-12 mb-4">
                            <HeaderTitle title="Dashboard" />
                        </div>
                        <div className="col-md-3 d-md-flex">
                            <div className="card radius-15">
                                <h5 className="card-header card-header-style text-white">APPLICATIONS</h5>
                                    {/*<BillingPieDonut userDashboardData={userDashboardData} />*/}
                                    <CredentialingPieDonutGraph userDashboardData={userDashboardData} />
                            </div>
                        </div>
                        <div className="col-md-9 d-md-flex mt-3 mt-md-0">
                            <div className="card radius-15 w-100">
                                <h5 className="card-header card-header-style text-white">UPDATES</h5>
                                    {/*<BillingAgingSummary ageSummary={userDashboardData?.aging_summary} />*/}
                                   <CredentialingAppUpdates appUpdates={appUpdates} />
                            </div>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-6">
                            <div className="card radius-15">
                                <h5 className="card-header card-header-style text-white">USER DETAILS</h5>
                                   <CredentialingUserDetail userDashboardData={userDashboardData} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card radius-15">
                                <h5 className="card-header card-header-style text-white">FINANCIALS</h5>
                                   <CredentialingFinancial userDashboardData={userDashboardData} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Dashboard;