import React, { useContext, useEffect, useState } from "react";
import { Step1PracticeDetails } from "./components/Step1PracticeDetails";
import { Step4UploadDocuments } from "./components/Step4UploadDocuments";
import {BillingContext} from "../context/BillingContext";
import {HeaderTitle} from "../../general/HeaderTitle";
import {BillingDetailsView} from "./BillingDetailsView";
import {Step3CreateLogins} from "./components/Step3CreateLogins";
import {Step2CreateProviderDetails} from "./components/Step2CreateProviderDetails";

export const AddBillingDetail = () => {
    const { isLoading, formName, billingDetail, backHome, getBillingDetail} = useContext(BillingContext);

    useEffect(()=>{
        getBillingDetail();
    }, [])
    const getForms = () => {
        switch (formName){
            case 'practice':
            case'createPractice' :
                return (<Step1PracticeDetails/>)
            case 'provider' :
            case 'createProvider' :
                return (<Step2CreateProviderDetails/>)
            case 'createLogin' :
                return (<Step3CreateLogins/>)
            case 'uploadDocument':
                return (<Step4UploadDocuments/>)
            default:
                return (<Step1PracticeDetails/>)
        }
    }
    if(billingDetail.length > 0 && formName==null) {
        return (<BillingDetailsView/>)
    } else {
        return (
            <div className="container-fluid">
                <div className="row mx-0">
                    <div className="col-12 bg-gray radius-15">
                        <HeaderTitle title="Billing Details" dispatchBy="billingDetail" back={backHome} />
                    </div>
                </div>
                <div className="row my-4">
                    <div className={`col-12 col-md-12 ${isLoading ? 'position-relative' : ''} `}>
                        {(isLoading) && (
                            <div className="loadingOverLay">
                                <h4>Processing! Please Wait...</h4>
                            </div>
                        )}
                        <div className="card border-none border-0">
                            {
                                getForms()
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

};